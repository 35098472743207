/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Grid, SortDirection, Spinner, TableFooter, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { DisponibilidadeDadosClinicosHistorico } from 'graphql/types.generated'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { useHistoricoPrint } from '../../hooks/useHistoricoPrint'
import { HistoricoAtendimentoFilterFormModel } from '../../model/historicoFilterFormModel'
import {
  CidadaoHistorico,
  HistoricoAtendimentoListagemTableModel,
  HistoricoAtendimentoModel,
} from '../../model/historicoModel'
import HistoricoAtendimentoTableHeader from './header/HistoricoAtendimentoTableHeader'
import HistoricoAtendimentoTableItem from './item/HistoricoAtendimentoTableItem'

export interface HistoricoAtendimentoTableProps {
  cidadao: CidadaoHistorico
  isLoading: boolean
  dataTable: HistoricoAtendimentoListagemTableModel

  tableProperties: ReturnType<typeof usePagedTableProps>
  countAtendimentosEnabledToPrint: number
  historicoPrintProps: ReturnType<typeof useHistoricoPrint>

  sort: SortDirection
  disponibilidadeDadosClinicos: DisponibilidadeDadosClinicosHistorico
  onSortChange(sort: SortDirection): void
  onChangeFilter: Dispatch<SetStateAction<HistoricoAtendimentoFilterFormModel>>
  refetchOnListagem(): void
}

const HISTORICO_PAGE_SIZES = [10, 30, 50, 100]

export default function HistoricoAtendimentoTable(props: HistoricoAtendimentoTableProps) {
  const {
    dataTable,
    isLoading,
    sort,
    disponibilidadeDadosClinicos,
    onSortChange,
    cidadao,
    refetchOnListagem,
    tableProperties,
    countAtendimentosEnabledToPrint,
    historicoPrintProps,
  } = props
  const theme = useTheme()

  const content = dataTable?.content
  const isHistoricoEmpty = content?.length === 0
  const classes = useMemo(() => styles(theme), [theme])

  const handleSortChange = (sort: SortDirection) => onSortChange(sort)

  const {
    print: printItemsSelected,
    toggleSelectItem: handleSelectItem,
    isItemSelected: isAtendimentoSelected,
    isAllPageItemsSelected,
    countSelectedInPage,
    toggleSelectPage: handleSelectPage,
    reset: clearSelections,
  } = historicoPrintProps

  const { page, onSizeChange, ...restTableProps } = tableProperties

  const handlePrintPage = (selected: boolean) => handleSelectPage(selected, content)

  const handleSizeChange = (size: number) => {
    clearSelections()
    return onSizeChange(size)
  }

  return (
    <VFlow style={classes.tableBox}>
      <HistoricoAtendimentoTableHeader
        sort={sort}
        onSortChange={handleSortChange}
        atendimentosEnabledToPrintCount={countAtendimentosEnabledToPrint}
        selectedCount={countSelectedInPage}
        selectPageToPrint={handlePrintPage}
        isAllPageItemsSelected={isAllPageItemsSelected}
        printHistorico={printItemsSelected}
      />
      <VFlow vSpacing={0}>
        {!isLoading && disponibilidadeDadosClinicos && (
          <div css={classes.alertProcessamentoWrapper}>
            {!disponibilidadeDadosClinicos.statusProcessamento && (
              <Alert type='warning'>
                {!disponibilidadeDadosClinicos.isVersaoMinimaOracle ? (
                  <Text color='alert'>
                    Alguns dados clínicos estão disponíveis apenas no detalhe dos registros pois a instalação não cumpre
                    os requisitos mínimos de infraestrutura.
                  </Text>
                ) : (
                  <Text color='alert'>
                    Alguns dados clínicos estão disponíveis apenas no detalhe dos registros devido à um erro no
                    processamento de dados.
                  </Text>
                )}
              </Alert>
            )}
          </div>
        )}
        {isLoading && (
          <div css={classes.loadingContainer}>
            <Spinner />
            Carregando resultados
          </div>
        )}
        {!isLoading && isHistoricoEmpty ? (
          <div css={classes.noResultContainer}>
            <Text fontStyle='italic'>A busca não encontrou resultados</Text>
          </div>
        ) : (
          <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
            <Grid style={classes.accordionWrapper}>
              {content?.map((historicoAtendimento: HistoricoAtendimentoModel) => (
                <HistoricoAtendimentoTableItem
                  key={historicoAtendimento.idAtendRecente || historicoAtendimento.codigoUnicoRegistro}
                  historicoAtendimento={historicoAtendimento}
                  cidadao={cidadao}
                  refetchOnListagem={refetchOnListagem}
                  checked={isAtendimentoSelected(historicoAtendimento)}
                  handleSelectToPrint={handleSelectItem}
                  historicoPrintProps={historicoPrintProps}
                />
              ))}
            </Grid>
          </Accordion>
        )}
      </VFlow>
      <div css={classes.footerWrapper}>
        <TableFooter
          {...restTableProps}
          page={page}
          onSizeChange={handleSizeChange}
          sizeOptions={HISTORICO_PAGE_SIZES}
          pageSize={dataTable?.pageInfo.size}
          style={classes.tableFooter}
        />
      </div>
    </VFlow>
  )
}

const styles = (theme: Theme) => {
  return {
    tableBox: css`
      border: 1px solid ${theme.pallete.divider};
      border-radius: ${theme.radius.modal};
    `,
    accordionWrapper: css`
      padding: 0.5rem 1rem;
    `,
    footerWrapper: css`
      border-top: 1px solid ${theme.pallete.divider};
    `,
    tableFooter: css`
      border: none;
    `,
    loadingContainer: css`
      color: ${theme.pallete.primary.main};
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      & > *:first-of-type {
        margin-right: 0.5rem;
      }
    `,
    noResultContainer: css`
      text-align: center;
      & > span {
        color: ${theme.pallete.text.secondary};
      }
    `,
    alertProcessamentoWrapper: css`
      padding: 0.5rem 1rem 0.5rem 9rem;
    `,
  }
}
