import { Cell, Grid } from 'bold-ui'
import { CheckboxField, DateField, FormRenderProps, NumberField, TextField } from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import moment from 'moment'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { cns, cpf, createValidator, dataNasc, ErrorObject, nome } from 'util/validation'

import {
  RelacaoCidadaoCuidadorModel,
  RelacaoCidadaoCuidadorSelectField,
} from '../selectFields/RelacaoCidadaoCuidadorSelectField'
import CidadaoADFormModel from './CidadaoADFormModel'

export const validate = createValidator<CuidadorFieldGroupModel>(
  {
    nomeCuidador: [nome],
    dataNascimento: [dataNasc],
  },
  (values: CuidadorFieldGroupModel) => {
    const errors: ErrorObject<CuidadorFieldGroupModel> = {}
    const [lengthCpf, cpfRule] = cpf
    const [lengthCns, cnsRule] = cns

    if (values?.cpfOuCns) {
      if (lengthCpf && cpfRule(values.cpfOuCns) && lengthCns && cnsRule(values.cpfOuCns)) {
        return { cpfOuCns: 'CPF/CNS inválido.' }
      }
    }

    return errors
  }
)

export interface CuidadorFieldGroupModel {
  naoPossuiCuidador?: boolean
  nomeCuidador?: string
  dataNascimento?: string
  cpfOuCns?: string
  relacao?: RelacaoCidadaoCuidadorModel
}

export interface CuidadorDadosFieldGroupProps {
  name: MetaPath<CuidadorFieldGroupModel>
  formProps: FormRenderProps<CidadaoADFormModel>
}

export default function CuidadorDadosFieldGroup(props: CuidadorDadosFieldGroupProps) {
  const { name, formProps } = props

  const values = resolveValue(formProps.values, name) || ({} as CuidadorFieldGroupModel)

  return (
    <Grid wrap>
      <Cell size={6}>
        <CheckboxField name={name.naoPossuiCuidador} label='Não possui cuidador' />
      </Cell>
      {!values.naoPossuiCuidador && (
        <>
          <Cell size={6} />
          <Cell size={6}>
            <TextField name={name.nomeCuidador} label='Nome' maxLength={70} uppercase />
          </Cell>
          <Cell size={6} />
          <Cell size={2}>
            <DateField name={name.dataNascimento} label='Data de nascimento' maxDate={moment().toDate()} />
          </Cell>
          <Cell size={2}>
            <NumberField name={name.cpfOuCns} label='CPF/CNS' maxLength={15} />
          </Cell>
          <Cell size={6}></Cell>
          <Cell size={3}>
            <RelacaoCidadaoCuidadorSelectField name={name.relacao} label='Relação com o cidadão' />
          </Cell>
        </>
      )}
    </Grid>
  )
}
