import { SelectField, SelectFieldProps } from 'components/form'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { LoteImunobiologicoFabricanteDocument } from 'graphql/hooks.generated'
import { LoteImunobiologicoFabricanteQuery, LoteImunobiologicoFabricanteQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type LoteImunobiologicoFabricanteSelectFieldModel = Partial<
  LoteImunobiologicoFabricanteQuery['fabricantesImunobiologico']['content'][0]
>

export interface LoteImunobiologicoFabricanteSelectFieldProps
  extends Omit<SelectFieldProps<LoteImunobiologicoFabricanteSelectFieldModel>, 'items' | 'itemToString'> {}

export function LoteImunobiologicoFabricanteSelectField(props: LoteImunobiologicoFabricanteSelectFieldProps) {
  const { ...rest } = props

  const { loading, ...asyncProps } = useAsyncQuerySelect<
    LoteImunobiologicoFabricanteSelectFieldModel,
    LoteImunobiologicoFabricanteQuery,
    LoteImunobiologicoFabricanteQueryVariables
  >({
    query: LoteImunobiologicoFabricanteDocument,
    extractItems: (data) => data?.fabricantesImunobiologico?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: 5,
          sort: ['nome'],
        },
      },
    }),
    fetchPolicy: 'cache-first',
  })

  const itemToString = (item: LoteImunobiologicoFabricanteSelectFieldModel) => item?.nome
  const createNewItem = (text: string) => ({ nome: text })

  return (
    <SelectField<LoteImunobiologicoFabricanteSelectFieldModel>
      itemToString={itemToString}
      createNewItem={createNewItem}
      {...asyncProps}
      {...rest}
    />
  )
}
