import { Heading, HFlow, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Odontograma } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { historicoSubsectionStyles } from 'view/atendimentos/detail/historico/model/historicoModel'
import { HistoricoEvolucoesOdontoTableRowModel } from 'view/atendimentos/detail/soap/plano/evolucoes-odontologicas/historico-evolucoes-odontologicas/HistoricoEvolucoesOdontoTable'

import { HistoricoOdontogramaTable } from './HistoricoOdontogramaTable'

interface HistoricoOdontogramaPanelProps {
  odontograma: Odontograma
  evolucoes: HistoricoEvolucoesOdontoTableRowModel[]
}

export const HistoricoOdontogramaPanel = (props: HistoricoOdontogramaPanelProps) => {
  const { odontograma, evolucoes } = props

  return (
    <Fragment>
      <Heading level={5} style={historicoSubsectionStyles.plano}>
        Odontograma
      </Heading>
      <VFlow>
        <HFlow hSpacing={0.25}>
          <HLabel title='Possui aparelho:'>{odontograma.aparelho ? 'Sim' : 'Não'}</HLabel>|
          <HLabel title='Possui contenção:'>{odontograma.contencao ? 'Sim' : 'Não'}</HLabel>|
          <Fragment>
            <HLabel title='Prótese:'>
              {odontograma.proteseTotalInferior || odontograma.proteseTotalSuperior ? (
                <Fragment>
                  <Text>Total</Text>
                  {odontograma.proteseTotalSuperior && ' superior'}
                  {odontograma.proteseTotalInferior && odontograma.proteseTotalSuperior && ' e'}
                  {odontograma.proteseTotalInferior && ' inferior'}
                </Fragment>
              ) : (
                '-'
              )}
            </HLabel>
          </Fragment>
        </HFlow>

        {evolucoes?.length > 0 && <HistoricoOdontogramaTable evolucoes={evolucoes} />}
      </VFlow>
    </Fragment>
  )
}
