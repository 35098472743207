import { Cell, Grid, HFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { resolveValue } from 'components/form/final-form/useField'
import { AcessoLotacaoFragment, ConfiguracoesAtencaoDomQuery } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

import { EquipeAdSelectField } from '../componente/EquipeAdSelectField'
import { ProfissionalAdSelectField } from '../componente/ProfissionalAdSelectField'
import { AtencaoDomiciliarModalidadeSelectField } from '../lista/selectFields/AtencaoDomiciliarModalidadeSelectField'
import { downloadRelatorioAd } from './downloadRelatorioAd'
import RelatorioAdFormModel from './RelatorioAdFormModel'

export type ConfiguracaoAdAtendimentoItemType = ConfiguracoesAtencaoDomQuery['configuracoesAtencaoDom']['emadsAtendemEmads']['content'][0]

export const validate = createValidator<RelatorioAdFormModel>(
  {
    periodo: [required],
  },
  (values: RelatorioAdFormModel, errors) => {
    if (!errors.periodo) errors.periodo = required(values?.periodo?.startDate)
    if (!errors.periodo) errors.periodo = required(values?.periodo?.endDate)
    if (!errors.periodo && moment(values.periodo.startDate).isBefore(dateZeroHour(new Date()))) {
      errors.periodo = 'Data inicial é menor que a data atual.'
    }
    if (!errors.periodo && moment(values?.periodo.endDate).isBefore(values?.periodo.startDate, 'date')) {
      errors.periodo = 'Data inicial é maior que a data final.'
    }

    return errors
  }
)

const path = metaPath<RelatorioAdFormModel>()

const dateZeroHour = (dt: Date) => {
  dt.setHours(0, 0, 0, 0)
  return dt
}

const dateFormat = (dt: Date) => {
  return moment(dt).format('YYYY-MM-DD')
}

export default function RelatorioAdForm() {
  const handleRejection = useErrorHandler()

  const gerarRelatorio = (values: RelatorioAdFormModel) => {
    const formData = new FormData()
    values?.periodo?.startDate && formData.append('dataInicio', dateFormat(values.periodo.startDate))
    values?.periodo?.endDate && formData.append('dataFim', dateFormat(values.periodo.endDate))
    values?.modalidade && values.modalidade.forEach((value, index) => formData.append(`modalidades[${index}]`, value))
    values?.equipe && formData.append('equipesId[0]', values.equipe.id)
    values?.profissional?.id && formData.append('profissionalId', values.profissional.id)
    return downloadRelatorioAd(formData).catch(handleRejection)
  }

  const { data, hasAuthorization } = useSession()

  const relatorioMunicipio = hasAuthorization(
    Permissions.atencaoDomiciliar.gerarRelatorioDeAdDaEquipe.gerarRelatorioDeAdDoMunicipio
  )
  const municipioId = data.acesso.municipio.id
  const idEquipeUsuario = relatorioMunicipio ? null : (data?.acesso as AcessoLotacaoFragment)?.equipe?.id

  const renderForm = (formProps: FormRenderProps<RelatorioAdFormModel>) => {
    const values = resolveValue(formProps.values, path) || ({} as RelatorioAdFormModel)

    const clearProfissional = () => {
      formProps.form.change(path.profissional.absolutePath(), null)
    }

    return (
      <Grid>
        <Cell size={4}>
          <DateRangeField name={path.periodo} required label='Período' minDate={dateZeroHour(new Date())} />
        </Cell>
        <Cell size={8}>
          <EquipeAdSelectField
            name={path.equipe}
            label='Equipe'
            onChange={clearProfissional}
            localidadeId={municipioId}
            equipeId={idEquipeUsuario}
            mostrarEquipesRelacionadas={true}
          />
        </Cell>
        <Cell size={4}>
          <AtencaoDomiciliarModalidadeSelectField name={path.modalidade} multiple label='Modalidade' />
        </Cell>
        <Cell size={8}>
          <ProfissionalAdSelectField
            name={path.profissional}
            label='Profissional'
            localidadeId={municipioId}
            equipeId={values?.equipe?.id || idEquipeUsuario}
            mostrarEquipesRelacionadas={values?.equipe?.id === undefined}
          />
        </Cell>
        <Cell size={12} alignSelf='center'>
          <HFlow justifyContent='flex-end'>
            <SubmitButton kind='primary' size='medium' handleSubmit={formProps.handleSubmit}>
              Imprimir
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return <Form<RelatorioAdFormModel> render={renderForm} onSubmit={gerarRelatorio} validate={validate} />
}
