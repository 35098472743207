import { Text, VFlow } from 'bold-ui'
import { Procedimento } from 'graphql/types.generated'
import React from 'react'

export type ProcedimentoSelectFieldItemModel = Procedimento

export const ProcedimentoSelectFieldItem = (item: ProcedimentoSelectFieldItemModel) => {
  const isSisab = isProcedimentoSisab(item.codigo)
  const isRefSisab = isProcedimentoSisab(item.procedimentoReferencia?.codigo)
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>{item.descricao.capitalize()}</Text>
      <Text>{isSisab ? `CDS` : `Código ${item.codigo}`}</Text>
      {item.procedimentoReferencia && (
        <>
          {isRefSisab
            ? `CDS ${item.procedimentoReferencia?.descricao.capitalize()}`
            : `Código ${item.procedimentoReferencia?.codigo}`}
        </>
      )}
    </VFlow>
  )
}

const exceptionCodes = ['ABEX012', 'ABEX013', 'ABEX022']

export const isProcedimentoSisab = (codigo?: string) => {
  return codigo?.match('^[A-Za-z]')
}

export const shouldUseProcedimentoReferencia = (codigo?: string) => {
  if (exceptionCodes.includes(codigo)) return false

  return isProcedimentoSisab(codigo)
}
