import { SelectField, SelectFieldProps } from 'components/form'
import { LocalAtendimentoSelectFieldDocument } from 'graphql/hooks.generated'
import { LocalAtendimentoSelectFieldQuery, LocalAtendimentoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export const LOCAL_ATENDIMENTO_UBS_ID = '1'

export type LocalAtendimentoSelectFieldModel = LocalAtendimentoSelectFieldQuery['localAtendimento']['content'][0]

const itemToString = (item: LocalAtendimentoSelectFieldModel) => item?.localAtendimentoExibicao.capitalize()

export interface LocalAtendimentoSelectFieldProps
  extends Omit<SelectFieldProps<LocalAtendimentoSelectFieldModel>, 'items' | 'itemToString'> {
  excludeUbs?: boolean
}

export function LocalAtendimentoSelectField(props: LocalAtendimentoSelectFieldProps) {
  const { excludeUbs = false, ...rest } = props

  const { ...asyncProps } = useAsyncQuerySelect<
    LocalAtendimentoSelectFieldModel,
    LocalAtendimentoSelectFieldQuery,
    LocalAtendimentoSelectFieldQueryVariables
  >({
    query: LocalAtendimentoSelectFieldDocument,
    extractItems: (data) => data?.localAtendimento.content,
    variables: (inputQuery: string): LocalAtendimentoSelectFieldQueryVariables => ({
      filtro: {
        query: inputQuery,
        excludeUbs,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['localAtendimento'],
        },
      },
    }),
  })

  return (
    <SelectField<LocalAtendimentoSelectFieldModel>
      label='Local de Atendimento'
      itemToString={itemToString}
      {...asyncProps}
      {...rest}
    />
  )
}
