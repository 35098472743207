/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, HFlow, Text, VFlow } from 'bold-ui'
import { isProcedimentoSisab } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { HLabel } from 'components/HLabel'
import { ResultadoExame } from 'graphql/types.generated'
import { Fragment } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate } from 'util/date/formatDate'
import { formatNumber } from 'util/number'
import { isEmpty } from 'util/validation/Util'
import {
  mapResultadosPuericulturaEnumLabel,
  resultadosExamesEspecificosFactory,
  showCodigoProcedimento,
} from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'

import { historicoSubsectionStyles } from '../../../model/historicoModel'

interface HistoricoResultadoExamesTableProps {
  examesResultados: ResultadoExame[]
}

export default function HistoricoResultadoExamesTable(props: HistoricoResultadoExamesTableProps) {
  const { examesResultados } = props

  const colunasTabelaResultadoExames = [
    {
      name: 'exame',
      header: 'Exame',
      render: (itemExame: ResultadoExame) => (
        <VFlow
          vSpacing={0.5}
          style={css`
            padding: 0.5rem 0;
          `}
        >
          {`${itemExame.exame.descricao} - ${showCodigoProcedimento(itemExame.exame.codigo)}`}
          <HFlow hSpacing={0.25}>
            <HLabel title='Data de solicitação:'>{formatDate(itemExame.dataSolicitacao)}</HLabel>
          </HFlow>
        </VFlow>
      ),
      style: css`
        width: 40%;
      `,
    },
    {
      name: 'resultado',
      header: 'Resultado',
      render: (item: ResultadoExame) => {
        const exameCodigo = isProcedimentoSisab(item.exame?.codigo)
          ? item.exame.procedimentoReferencia?.codigo
          : item.exame.codigo
        const exameEspecifico = resultadosExamesEspecificosFactory?.[exameCodigo]

        const hasIgSemanas = !isUndefinedOrNull(item.especifico?.igSemanas)
        const hasIgDias = !isUndefinedOrNull(item.especifico?.igDias)

        return (
          <VFlow
            vSpacing={0}
            style={css`
              padding: 0.5rem 0;
            `}
          >
            {item.especifico && (
              <Fragment>
                {item.especifico.valor && (
                  <Text>{`${
                    !isEmpty(exameEspecifico.properties.precision)
                      ? formatNumber(item.especifico.valor, exameEspecifico.properties.precision)
                      : formatNumber(item.especifico.valor, 0)
                  } ${exameEspecifico.properties.unit}`}</Text>
                )}
                {item.especifico.resultadoExamePuericulturaEnum && (
                  <Text fontWeight='bold'>
                    {mapResultadosPuericulturaEnumLabel[item.especifico.resultadoExamePuericulturaEnum]}
                  </Text>
                )}
                <HFlow hSpacing={0.25}>
                  {hasIgSemanas && (
                    <HLabel title='Idade gestacional eco:'>
                      {`${'semana'.pluralizeAndConcatValue(item.especifico.igSemanas)} ${
                        hasIgDias ? `e ${'dia'.pluralizeAndConcatValue(item.especifico.igDias)}` : ''
                      }`}
                    </HLabel>
                  )}
                  {hasIgSemanas && item.especifico.dpp && <Text>|</Text>}
                  {item.especifico.dpp && <HLabel title='DPP eco:'>{formatDate(item.especifico.dpp)}</HLabel>}
                </HFlow>
              </Fragment>
            )}
            {item.resultado?.removeTags() && (
              <Text
                style={css`
                  white-space: pre-line;
                `}
                dangerouslySetInnerHTML={{ __html: item.resultado }}
              />
            )}
            <HFlow hSpacing={0.25}>
              <HLabel title='Data de realização:'>{formatDate(item.dataRealizacao)}</HLabel> |
              <HLabel title='Data de resultado:'>{formatDate(item.dataResultado)}</HLabel>
            </HFlow>
          </VFlow>
        )
      },
      style: css`
        width: 40%;
      `,
    },
  ]

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={4} style={historicoSubsectionStyles.objetivo}>
        Resultados de exames
      </Heading>
      <DataTable<ResultadoExame>
        rows={examesResultados}
        columns={colunasTabelaResultadoExames}
        style={css`
          overflow-wrap: anywhere;
        `}
      />
    </VFlow>
  )
}
