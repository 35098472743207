import { Alert, Heading, Link, VFlow } from 'bold-ui'
import React from 'react'
import { useHistory } from 'react-router'

import acessar_novidades_versao from '../../images/novidades/modal_notificacao.gif'
import { NewsModalBodyLinks } from './NewsModalBodyLinks'

type newsModalBodyTypes = {
  handleModalClose?: () => void
}

export function NewsModalBody({ handleModalClose }: newsModalBodyTypes) {
  const history = useHistory()

  const handleOnNovidadesVersaoClicked = () => {
    handleModalClose()
    history.push('/novidades')
  }

  return (
    <>
      <VFlow>
        <VFlow>
          <Heading level={1}>Novidades da versão</Heading>
          <Alert type='info' inline>
            <strong>O PEC está sendo redesenhado para ficar ainda mais fácil de usar!</strong> A cada versão, diferentes
            módulos serão atualizados com a nova interface. Para receber as próximas novidades, o sistema deve ser
            atualizado com a versão mais recente disponível.
          </Alert>
        </VFlow>

        <VFlow>
          <Heading level={4}>Módulos com novidades:</Heading>
          <NewsModalBodyLinks />
        </VFlow>

        <VFlow>
          <Heading level={4}>Para acessar as Novidades da Versão:</Heading>
          <p>
            No cabeçalho do sistema, abra o menu clicando sobre seu nome e em seguida na opção{' '}
            <Link onClick={handleOnNovidadesVersaoClicked}>Novidades da versão</Link>.
          </p>
          <img
            style={{ width: '100%', height: '250px', objectFit: 'cover', objectPosition: '0 30%', border: 'solid 1px' }}
            src={acessar_novidades_versao}
            alt='Imagem de como acessar novidades da versão'
          />
        </VFlow>
      </VFlow>
    </>
  )
}
