import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { resolveName } from 'components/form/final-form/useField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { AdicionarDenteSupranumerarioPopup } from './AdicionarDenteSupranumerarioPopup'

export interface EvolucaoDenteSupranumerarioModel {
  data: Date
  local: string
  procedimentos: ProcedimentoOdontoSelectModel[]
  observacao?: string
  lastUpdate: Instant
}

export interface DentesSupranumerariosFieldProps {
  name: MetaPath<EvolucaoDenteSupranumerarioModel[]>
  cidadaoDataNascimento: LocalDate
}

export default function DentesSupranumerariosField(props: DentesSupranumerariosFieldProps) {
  const { name, cidadaoDataNascimento } = props

  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
  } = useAtendimentoContext()

  const renderFieldArray = (fieldArrayRenderProps: FieldArrayRenderProps<EvolucaoDenteSupranumerarioModel, any>) => {
    const {
      fields: { push },
    } = fieldArrayRenderProps

    const pushEvolucao = (value: EvolucaoDenteSupranumerarioModel) => push({ ...value, data: dataAtendimento })

    const dataAtendimentoAsLocalDate = dateAsYyyyMmDd(dataAtendimento)

    return (
      <AdicionarDenteSupranumerarioPopup
        cidadaoDataNascimento={cidadaoDataNascimento}
        dataAtendimento={dataAtendimentoAsLocalDate}
        onSubmit={pushEvolucao}
      />
    )
  }

  return <FieldArray name={resolveName(name)} render={renderFieldArray} />
}
