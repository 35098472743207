import { Omit } from 'bold-ui'
import { DocumentNode } from 'graphql'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../final-form/SelectField'
import { useAsyncQuerySelect } from './useAsyncQuerySelect'

export interface AsyncSelectQueryFieldProps<OptionType, TData, TVariables>
  extends Omit<SelectFieldProps<OptionType>, 'items' | 'itemToString'> {}

export interface InternalProps<OptionType, TData, TVariables> extends Omit<SelectFieldProps<OptionType>, 'items'> {
  query: DocumentNode
  variables(inputQuery: string): TVariables
  extractItems(data: TData): OptionType[]
}

/**
 * @deprecated Utiliza a hook `useAsyncQuerySelect`
 */
export function AsyncQuerySelectField<OptionType, TData, TVariables>(
  props: InternalProps<OptionType, TData, TVariables>
) {
  const { query, variables, extractItems, ...rest } = props

  const asyncProps = useAsyncQuerySelect({ query, variables, extractItems })

  return <SelectField<OptionType> {...asyncProps} {...rest} />
}
