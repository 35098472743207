import { SelectField, SelectFieldProps } from 'components/form'
import { FormaFarmaceuticaSelectFieldDocument } from 'graphql/hooks.generated'
import { FormaFarmaceuticaSelectFieldQuery, FormaFarmaceuticaSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type FormaFarmaceuticaSelectFieldModel = Partial<
  FormaFarmaceuticaSelectFieldQuery['formasFarmaceuticas']['content'][0]
>

export function FormaFarmaceuticaSelectField(
  props: Omit<SelectFieldProps<FormaFarmaceuticaSelectFieldModel>, 'items' | 'itemToString'>
) {
  const asyncProps = useAsyncQuerySelect<
    FormaFarmaceuticaSelectFieldModel,
    FormaFarmaceuticaSelectFieldQuery,
    FormaFarmaceuticaSelectFieldQueryVariables
  >({
    query: FormaFarmaceuticaSelectFieldDocument,
    extractItems: (data) => data?.formasFarmaceuticas?.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: 10,
          sort: ['nomeFormaFarmaceutica'],
        },
      },
    }),
  })

  const itemToString = (item: FormaFarmaceuticaSelectFieldModel) => item?.nome

  return <SelectField<FormaFarmaceuticaSelectFieldModel> itemToString={itemToString} {...asyncProps} {...props} />
}
