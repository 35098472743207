import { Button, Cell, Grid, Heading, HFlow, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { Form, FormRenderProps, RadioField, SubmitButton } from 'components/form'
import DateRangeField, { LocalDateRange } from 'components/form/field/DateRangeField'
import createDecorator from 'final-form-calculate'
import { BuscaPeriodoEnum } from 'graphql/types.generated'
import React, { useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject } from 'util/validation'
import { EquipeAdSelectField, EquipeSelectModel } from 'view/ad/componente/EquipeAdSelectField'

import { createFilterListaAdCalculations } from '../components/CidadaoADCalculator'
import {
  AtencaoDomiciliarCondutaDesfechoModel,
  AtencaoDomiciliarCondutaDesfechoSelectField,
} from '../selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import {
  AtencaoDomiciliarModalidadeModel,
  AtencaoDomiciliarModalidadeSelectField,
} from '../selectFields/AtencaoDomiciliarModalidadeSelectField'

export interface AtencaoDomiciliarFilterPopperModel {
  tipoBuscaPeriodo?: string
  periodoBusca?: LocalDateRange
  condutaDesfecho?: AtencaoDomiciliarCondutaDesfechoModel
  modalidade?: AtencaoDomiciliarModalidadeModel
  equipe?: EquipeSelectModel
}

export const meta = metaPath<AtencaoDomiciliarFilterPopperModel>()

export interface AtencaoDomiciliarFilterPopperProps {
  equipeId?: ID
  initialValues?: AtencaoDomiciliarFilterPopperModel
  isCoordenadorAd?: boolean
  localidadeId?: ID
  onSubmit(values: AtencaoDomiciliarFilterPopperModel): void
  onClose(): void
}

export const validate = createValidator<AtencaoDomiciliarFilterPopperModel>(
  {},
  (form: AtencaoDomiciliarFilterPopperModel, errors: ErrorObject<AtencaoDomiciliarFilterPopperModel>) => {
    if (form.tipoBuscaPeriodo && form.tipoBuscaPeriodo !== BuscaPeriodoEnum.PERIODO_NENHUM)
      if (!form.periodoBusca?.startDate && !form.periodoBusca?.endDate)
        errors.periodoBusca = 'Preenchimento obrigatório.'
    return errors
  }
)

export function AtencaoDomiciliarFilterPopper(props: AtencaoDomiciliarFilterPopperProps) {
  const { initialValues, onSubmit, onClose, isCoordenadorAd, equipeId, localidadeId } = props

  const renderForm = (formProps: FormRenderProps<AtencaoDomiciliarFilterPopperModel>) => {
    return (
      <Box style={{ width: '26.25rem', height: '27.5rem' }}>
        <Grid gap={1}>
          <Cell size={12}>
            <Heading level={5} style={{ paddingBottom: '0.5rem' }}>
              Buscar por:
            </Heading>
            <VFlow vSpacing={0.5}>
              <RadioField name={meta.tipoBuscaPeriodo} label='Nenhum' value={BuscaPeriodoEnum.PERIODO_NENHUM} />
              <HFlow hSpacing={0.5} justifyContent='space-between' style={{ paddingRight: '0.5rem' }}>
                <RadioField
                  name={meta.tipoBuscaPeriodo}
                  label='Período da última visita'
                  value={BuscaPeriodoEnum.PERIODO_ULTIMA_VISITA}
                />
                <RadioField
                  name={meta.tipoBuscaPeriodo}
                  label='Período que entrou em AD'
                  value={BuscaPeriodoEnum.PERIODO_ENTROU_EM_AD}
                />
              </HFlow>
              <HFlow hSpacing={0.5} justifyContent='space-between'>
                <RadioField
                  name={meta.tipoBuscaPeriodo}
                  label='Período da próxima visita'
                  value={BuscaPeriodoEnum.PERIODO_PROXIMA_VISITA}
                />
                <RadioField
                  name={meta.tipoBuscaPeriodo}
                  label='Período de registro de óbito'
                  value={BuscaPeriodoEnum.PERIODO_REGISTRO_OBITO}
                />
              </HFlow>
            </VFlow>
          </Cell>
          <Cell size={12}>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <DateRangeField
                  name={meta.periodoBusca}
                  label='Período'
                  disabled={values.tipoBuscaPeriodo === BuscaPeriodoEnum.PERIODO_NENHUM}
                  required={values.tipoBuscaPeriodo !== BuscaPeriodoEnum.PERIODO_NENHUM}
                />
              )}
            </FormSpy>
          </Cell>
          <Cell size={6}>
            <AtencaoDomiciliarModalidadeSelectField name={meta.modalidade} label='Modalidade' />
          </Cell>
          <Cell size={6}>
            <AtencaoDomiciliarCondutaDesfechoSelectField
              name={meta.condutaDesfecho}
              label='Conduta/Desfecho'
              defaultValue={initialValues.condutaDesfecho}
            />
          </Cell>
          <Cell size={12}>
            <EquipeAdSelectField
              name={meta.equipe}
              equipeId={isCoordenadorAd ? undefined : equipeId}
              mostrarEquipesRelacionadas={!isCoordenadorAd}
              localidadeId={isCoordenadorAd ? localidadeId : undefined}
              label='Equipe responsável'
            />
          </Cell>
          <Cell size={12}>
            <HFlow justifyContent='flex-end'>
              <Button size='small' onClick={onClose}>
                Fechar
              </Button>
              <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                Filtrar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  const decorator = useMemo(() => createDecorator(...createFilterListaAdCalculations()), [])

  return (
    <Form<AtencaoDomiciliarFilterPopperModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      decorators={[decorator]}
    />
  )
}
