import { Tag, useTheme, VFlow } from 'bold-ui'
import { Form, PasswordField, SubmitButton, TextField } from 'components/form'
import { useCapslockState } from 'hooks/useCapslockState'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'

export interface LoginFormModel {
  username: string
  password: string
  force: boolean
}

const validator = createValidator<LoginFormModel>({
  username: [required],
  password: [required],
})

interface LoginFormProps {
  onLogin(values: LoginFormModel): Promise<any>
}

const path = metaPath<LoginFormModel>()

export const LoginForm = (props: LoginFormProps) => {
  const { onLogin } = props

  const theme = useTheme()
  const isCapsOn = useCapslockState()

  const renderLogin = (formRenderProps: FormRenderProps<LoginFormModel>) => (
    <form onSubmit={formRenderProps.handleSubmit} noValidate>
      <VFlow>
        <TextField name={path.username} label='Usuário' clearable={false} autoFocus />
        <VFlow vSpacing={0.5}>
          <PasswordField name='password' label='Senha' clearable={false} maxLength={20} />
          {isCapsOn && <Tag style={{ display: 'inline-block' }}>Caps lock ativado</Tag>}
        </VFlow>

        <SubmitButton
          style={{
            background: `linear-gradient(to right, ${theme.pallete.primary.c40}, ${theme.pallete.primary.c60})`,
            color: theme.pallete.gray.c100,
            border: 'none',
          }}
          block
          handleSubmit={formRenderProps.handleSubmit}
          data-testid='LoginForm.access-button'
        >
          Acessar
        </SubmitButton>
      </VFlow>
      <input type='submit' hidden />
    </form>
  )

  return <Form<LoginFormModel> validate={validator} onSubmit={onLogin} render={renderLogin} />
}
