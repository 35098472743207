import { yellow } from 'bold-ui/lib/styles/colors'
import { AcessoProfissionalType } from 'components/auth/model'
import React from 'react'

import { SoapSection } from '../SoapSection'
import AvaliacaoForm, { AvaliacaoFormProps } from './AvaliacaoForm'

export interface AvaliacaoSectionProps extends AvaliacaoFormProps {
  acessoProfissional: AcessoProfissionalType
}

export function AvaliacaoSection(props: AvaliacaoSectionProps) {
  return (
    <SoapSection initial='A' title='Avaliação' color={yellow}>
      <AvaliacaoForm {...props} />
    </SoapSection>
  )
}
