/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text } from 'bold-ui'
import { Fragment } from 'react'

import { TipoAtendimentoHistoricoProps } from '../../../../model/historicoModel'

interface HistoricoAccordionHeaderTitleTextProps {
  tipoAtendimentoData: TipoAtendimentoHistoricoProps
  horaOuTurnoAtendimento: string
}

export default function HistoricoAccordionHeaderTitleText({
  tipoAtendimentoData,
  horaOuTurnoAtendimento,
}: HistoricoAccordionHeaderTitleTextProps) {
  return (
    <Fragment>
      {tipoAtendimentoData.icon}
      <Text
        fontWeight='bold'
        fontSize={1}
        style={css`
          color: ${tipoAtendimentoData.iconColor};
        `}
      >
        {tipoAtendimentoData.descricao}
      </Text>
      {horaOuTurnoAtendimento && (
        <Fragment>
          <Text
            fontSize={1}
            style={css`
              color: ${tipoAtendimentoData.iconColor};
            `}
          >
            |
          </Text>
          <Text
            fontSize={1}
            style={css`
              color: ${tipoAtendimentoData.iconColor};
            `}
          >
            {horaOuTurnoAtendimento.capitalize()}
          </Text>
        </Fragment>
      )}
    </Fragment>
  )
}
