import { Button, ExternalStyles, Theme, useStyles } from 'bold-ui'
import { css } from 'emotion'
import React from 'react'
import { useHistory } from 'react-router'

import { SubmitButton } from './final-form/SubmitButton'

interface FormFooterProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
  style?: ExternalStyles
}

export const FormFooter = ({ style, ...props }: FormFooterProps) => {
  const { classes } = useStyles(createStyles)

  return (
    <div className={css(classes.container, style)} {...props}>
      {props.children}
    </div>
  )
}

export interface DefaultFormFooterProps {
  onCancel?(): void
  handleSubmit(event?: React.SyntheticEvent<HTMLFormElement>): void
}

export function DefaultFormFooter(props: DefaultFormFooterProps) {
  const history = useHistory()

  return (
    <FormFooter>
      <Button onClick={props.onCancel || history.goBack} data-testid='FormFooter.cancelar'>
        Cancelar
      </Button>
      <SubmitButton handleSubmit={props.handleSubmit} data-testid='FormFooter.salvar'>
        Salvar
      </SubmitButton>
    </FormFooter>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    borderTop: `1px solid ${theme.pallete.divider}`,
    padding: '1rem 0',
    margin: '2rem 0',
    textAlign: 'right',
    '& > *:not(:last-child)': {
      marginRight: '1rem',
    },
  } as React.CSSProperties,
})
