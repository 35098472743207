import { Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { FichaNotificacaoCasoSuspeitoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  FichaNotificacaoCasoSuspeitoSelectFieldQuery,
  FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

export type FichaNotificacaoSelectModel = FichaNotificacaoCasoSuspeitoSelectFieldQuery['fichasNotificacao']['content'][0]

export interface FichaNotificacaoSelectFieldProps
  extends Omit<SelectFieldProps<FichaNotificacaoSelectModel>, 'items' | 'itemToString'> {
  cidadaoId: ID
}

export function FichaNotificacaoCasoSuspeitoSelectField(props: FichaNotificacaoSelectFieldProps) {
  const { cidadaoId, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    FichaNotificacaoSelectModel,
    FichaNotificacaoCasoSuspeitoSelectFieldQuery,
    FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
  >({
    query: FichaNotificacaoCasoSuspeitoSelectFieldDocument,
    extractItems: (data) => data?.fichasNotificacao?.content,
    variables: (inputString) => ({
      input: {
        cidadaoId: cidadaoId,
        query: inputString,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  const itemToString = (item: FichaNotificacaoSelectModel) => item && item.nomeNotificacao

  const renderItem = (item: FichaNotificacaoSelectModel) => {
    return <Text>{item.nomeNotificacao}</Text>
  }

  return (
    <SelectField<FichaNotificacaoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...asyncProps}
      {...rest}
    />
  )
}
