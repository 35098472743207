import { HFlow } from 'bold-ui'
import { ExposedDropdown } from 'components/dropdown/ExposedDropdown'
import { OrdemListaAtendimentoEnum } from 'graphql/types.generated'
import React from 'react'
import {
  ListaAtendimentoDescription,
  ListaAtendimentoSortDropdownProps,
} from 'view/atendimentos/list/filter/ListaAtendimentoSortDropdown'

const ListaRegistroTardioSortMap: {
  [key in Exclude<
    OrdemListaAtendimentoEnum,
    OrdemListaAtendimentoEnum.CLASSIFICACAO_RISCO
  >]: ListaAtendimentoDescription
} = {
  [OrdemListaAtendimentoEnum.ORDEM_CHEGADA_CRESCENTE]: { descricao: 'Data do atendimento crescente' },
  [OrdemListaAtendimentoEnum.ORDEM_CHEGADA_DECRESCENTE]: { descricao: 'Data do atendimento decrescente' },
}

export function ListaRegistroTardioSortDropdown(props: ListaAtendimentoSortDropdownProps) {
  const { onChange, filter } = props

  const onChangeSelect = (sort: OrdemListaAtendimentoEnum) => {
    onChange({
      ...props.filter,
      ordem: sort,
    })
  }

  const itemToString = (sort: OrdemListaAtendimentoEnum) => sort && ListaRegistroTardioSortMap[sort]?.descricao

  return (
    <HFlow hSpacing={0.25} alignItems='center' justifyContent='flex-end'>
      <ExposedDropdown<OrdemListaAtendimentoEnum>
        label='Ordenar por:'
        selectedItem={filter.ordem}
        items={Object.keys(ListaRegistroTardioSortMap) as OrdemListaAtendimentoEnum[]}
        itemToString={itemToString}
        onChange={onChangeSelect}
      />
    </HFlow>
  )
}
