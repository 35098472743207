/**
 * É usado um e.preventDefault() para impedir que o form valide os campos do SOAP ao pressionar a tecla enter.
 * Para manter a usabilidade da tecla enter, é criado um evento de clique do mouse para obter o mesmo efeito.
 * Elementos html do tipo <div> não suportam a função click(), nesses casos foi disparado um evento de barra de espaço,
 * obtendo o mesmo efeito da tecla enter.
 *
 * Para manter a usabilidade da TextArea, permitindo pular linhas com o Enter,
 * quando o target é uma TextArea, mantem-se o comportamento padrao dos eventos.
 *
 */

export const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
  const target: any = event.target

  if (event.key === 'Enter' && target.tagName !== 'TEXTAREA') {
    event.preventDefault()

    if (target.tagName === 'DIV') {
      const keyboardEvent = new KeyboardEvent('keydown', { key: 'Space' })
      target.dispatchEvent(keyboardEvent)
    } else {
      target.click()
    }
  }
}
