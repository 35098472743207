/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Heading, Text, VFlow } from 'bold-ui'
import { isProcedimentoSisab } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoProcedimentoDwQuery } from 'graphql/hooks.generated'
import { isEmpty, partition } from 'lodash'
import {
  HistoricoDwPanelProps,
  historicoSubsectionStyles,
} from 'view/atendimentos/detail/historico/model/historicoModel'
import {
  mountInfosAdministrativas,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoSection from '../../../../HistoricoSection'
import HistoricoDetailTable from '../../../../table/HistoricoDetailTable'
import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'

interface HistoricoProcedimentoDwProps extends HistoricoDwPanelProps {}

export default function HistoricoProcedimentoDw({
  uuidAtend,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
}: HistoricoProcedimentoDwProps) {
  const {
    data: { historicoProcedimentoDw: detalheProcedimentoDw },
    loading,
  } = useHistoricoProcedimentoDwQuery({
    variables: {
      input: {
        uuidAtend,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!detalheProcedimentoDw) return <HistoricoNoDetailAvailable />

  const [procedimentosSisab, procedimentosSigtap] = partition(detalheProcedimentoDw.procedimentos, (procedimento) =>
    isProcedimentoSisab(procedimento.codigoProcedimento)
  )

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativas(detalheProcedimentoDw)}
    >
      <VFlow>
        <HistoricoSection section='S'>
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        </HistoricoSection>
        <HistoricoSection section='O'>
          <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
        </HistoricoSection>
        <HistoricoSection section='P'>
          {!isEmpty(procedimentosSisab) ? (
            <VFlow vSpacing={0.5}>
              <Heading level={5} style={historicoSubsectionStyles.plano}>
                Intervenções e/ou procedimentos clínicos
              </Heading>
              <HistoricoDetailTable hasLines>
                <VFlow>
                  {procedimentosSisab.map((procedimento) => (
                    <Text>
                      {procedimento.descricaoProcedimento} - {procedimento.codigoProcedimento}
                    </Text>
                  ))}
                </VFlow>
              </HistoricoDetailTable>
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
        <HistoricoSection section='D'>
          {!isEmpty(procedimentosSigtap) ? (
            <VFlow vSpacing={0.5}>
              <Heading level={5}>Procedimentos Administrativos (SIGTAP)</Heading>
              <HistoricoDetailTable hasLines>
                <VFlow>
                  {procedimentosSigtap.map((procedimento) => (
                    <Text>
                      {procedimento.descricaoProcedimento} - {procedimento.codigoProcedimento}
                    </Text>
                  ))}
                </VFlow>
              </HistoricoDetailTable>
            </VFlow>
          ) : (
            <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
          )}
        </HistoricoSection>
      </VFlow>
    </HistoricoGapPanel>
  )
}
