import { Omit, TextField as BoldTextField, TextFieldProps as BoldTextFieldProps } from 'bold-ui'
import { composeHandlers } from 'bold-ui/lib/util/react'
import React, { KeyboardEvent, useCallback } from 'react'

import { UseFieldProps, usePecField } from '../useField'
import { getFieldError } from '../util'

export type TextFieldProps = UseFieldProps<any> &
  Omit<BoldTextFieldProps, 'name'> & {
    uppercase?: boolean
    lowercase?: boolean
  }

export function TextField(props: TextFieldProps) {
  const { input, meta, uppercase, lowercase, onInput, ...rest } = usePecField(props)

  const handleInput = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement
      const selectionStart = target.selectionStart
      const selectionEnd = target.selectionEnd

      if (uppercase) {
        target.value = target.value?.toLocaleUpperCase()
        target.setSelectionRange(selectionStart, selectionEnd)
      }

      if (lowercase) {
        target.value = target.value?.toLocaleLowerCase()
        target.setSelectionRange(selectionStart, selectionEnd)
      }
    },
    [lowercase, uppercase]
  )

  return (
    <BoldTextField error={getFieldError(meta)} onInput={composeHandlers(handleInput, onInput)} {...input} {...rest} />
  )
}
