import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { TipoLogradouroSelectFieldDocument } from 'graphql/hooks.generated'
import { TipoLogradouroSelectFieldQuery, TipoLogradouroSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type TipoLogradouroSelectModel = TipoLogradouroSelectFieldQuery['tiposLogradouro']['content'][0]

export interface TipoLogradouroSelectFieldProps
  extends Omit<SelectFieldProps<TipoLogradouroSelectModel>, 'items' | 'itemToString'> {}

export function TipoLogradouroSelectField(props: TipoLogradouroSelectFieldProps) {
  const asyncProps = useAsyncQuerySelect<
    TipoLogradouroSelectModel,
    TipoLogradouroSelectFieldQuery,
    TipoLogradouroSelectFieldQueryVariables
  >({
    query: TipoLogradouroSelectFieldDocument,
    extractItems: (data) => data && data.tiposLogradouro && data.tiposLogradouro.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  const itemToString = (option: TipoLogradouroSelectModel) => option && option.nome

  return <SelectField<TipoLogradouroSelectModel> itemToString={itemToString} {...asyncProps} {...props} />
}
