/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Text, useTheme, VFlow } from 'bold-ui'
import { format } from 'date-fns'
import { Fragment } from 'react'

import { useHistoricoPrint } from '../../../hooks/useHistoricoPrint'
import { CidadaoHistorico, HistoricoAtendimentoModel } from '../../../model/historicoModel'
import HistoricoAccordion from '../../accordion/HistoricoAccordion'

interface historicoAtendimentoTableItemProps {
  historicoAtendimento: HistoricoAtendimentoModel
  cidadao: CidadaoHistorico
  checked: boolean
  refetchOnListagem(): void
  handleSelectToPrint?(checked: boolean, historico: HistoricoAtendimentoModel): void
  historicoPrintProps: ReturnType<typeof useHistoricoPrint>
}

export default function HistoricoAtendimentoTableItem(props: historicoAtendimentoTableItemProps) {
  const { historicoAtendimento, cidadao, refetchOnListagem, checked, handleSelectToPrint, historicoPrintProps } = props
  const theme = useTheme()

  const createDateContent = () => {
    const [day, month, year] = format(historicoAtendimento.dataAtendimento, 'dd/MM/yyyy').split('/')
    return (
      <VFlow vSpacing={0}>
        <Text fontSize={1} fontWeight='bold'>
          {`${day}/${month}`}
        </Text>
        <Text fontSize={0.975} fontWeight='bold'>
          {year}
        </Text>
      </VFlow>
    )
  }

  return (
    <Fragment>
      <Cell
        size={1}
        style={css`
          color: ${theme.pallete.gray.c20};
          text-align: center;
        `}
      >
        {historicoAtendimento.dataAtendimento && historicoAtendimento.showDataAtendimento && createDateContent()}
      </Cell>
      <Cell size={11}>
        <HistoricoAccordion
          historicoAtendimento={historicoAtendimento}
          cidadao={cidadao}
          refetchOnListagem={refetchOnListagem}
          checked={checked}
          onSelectToPrint={handleSelectToPrint}
          historicoPrintProps={historicoPrintProps}
        />
      </Cell>
    </Fragment>
  )
}
