import {
  Button,
  DateRange,
  FormControl,
  HeadingSection,
  HFlow,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  VFlow,
} from 'bold-ui'
import { InlineErrorList } from 'components/error/InlineErrorList'
import DateRangeField from 'components/form/field/DateRangeField'
import { ProfissionaisRelatorioInconsistenciaSelectField } from 'components/form/field/select/ProfissionaisRelatorioInconsistenciaSelectField'
import { CheckboxField, Form } from 'components/form/final-form'
import { format, subDays } from 'date-fns'
import { noop } from 'lodash'
import React, { Dispatch, useState } from 'react'
import { FormRenderProps } from 'react-final-form'

import { downloadRelatorioInconsistencia } from './downloadRelatorioInconsistencia'
import { RelatorioInconsistenciasFormModel } from './model'
import { validator } from './validator'

interface RelatorioInconsistenciaModalModel {
  onClose: Dispatch<void>
}

export const RelatorioInconsistenciaModal = ({ onClose }: RelatorioInconsistenciaModalModel) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [error, setError] = useState<any>()

  const initialValues = {
    periodo: {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
    } as DateRange,
  } as RelatorioInconsistenciasFormModel

  const handleSubmit = (values: RelatorioInconsistenciasFormModel) => {
    const formData = new FormData()
    values.periodo?.startDate && formData.append('dataInicio', format(values.periodo.startDate, 'yyyy-MM-dd'))
    values.periodo?.endDate && formData.append('dataFim', format(values.periodo.endDate, 'yyyy-MM-dd'))
    values.responsavel && formData.append('nomeResponsavel', values.responsavel.responsavel)
    values.tipoRecebimento && formData.append('tipoRecebimento', values.tipoRecebimento.toString())

    return downloadRelatorioInconsistencia(formData)
      .then(() => {
        setIsOpen(false)
        onClose()
      })
      .catch(setError)
  }

  const renderForm = (formProps: FormRenderProps<RelatorioInconsistenciasFormModel>) => {
    const closeAndClear = () => {
      setTimeout(formProps.form.reset)
      setError(null)
      setIsOpen(false)
      onClose()
    }

    const { startDate, endDate } = formProps.values.periodo
    const dataInicio = startDate && format(startDate, 'yyyy-MM-dd')
    const dataFim = endDate && format(endDate, 'yyyy-MM-dd')

    return (
      <Modal style={{ overflow: 'visible' }} size='small' onClose={closeAndClear} open={isOpen}>
        <ModalBody>
          <HeadingSection vSpace={0.25} level={1} title='Gerar relatório de inconsistências'>
            <VFlow vSpacing={!error ? 2 : 1}>
              <Text>Selecione os filtros desejados</Text>
              <InlineErrorList error={error} />
              <VFlow>
                <HFlow>
                  <DateRangeField name='periodo' required label='Período' maxDate={new Date()} />
                </HFlow>
                <ProfissionaisRelatorioInconsistenciaSelectField
                  disabled={!dataFim || !dataInicio}
                  dataInicio={dataInicio}
                  dataFim={dataFim}
                  name='responsavel'
                  label='Responsável'
                />
                <VFlow vSpacing={0.5}>
                  <FormControl label='Tipo de recebimento'>
                    <HFlow>
                      <CheckboxField name='tipoRecebimento' label='Arquivo' value='3' />
                      <CheckboxField name='tipoRecebimento' label='Online' value='2' />
                    </HFlow>
                  </FormControl>
                </VFlow>
              </VFlow>
            </VFlow>
          </HeadingSection>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Button onClick={closeAndClear}>Cancelar</Button>
            <Button onClick={formProps.handleSubmit} kind='primary'>
              Gerar
            </Button>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<RelatorioInconsistenciasFormModel>
      onSubmitFailed={noop}
      onSubmit={handleSubmit}
      render={renderForm}
      validate={validator}
      initialValues={initialValues}
    />
  )
}
