import { Cell, Grid, HFlow, Text, useTheme, VFlow } from 'bold-ui'
import { CboSelectField, FormRenderProps, TextField } from 'components/form'
import { EscolaridadeSelectField } from 'components/form/field/select/EscolaridadeSelectField'
import { EstadoCivilSelectField } from 'components/form/field/select/EstadoCivilSelectField'
import { IdentidadeGeneroSelectField } from 'components/form/field/select/IdentidadeGeneroSelectField'
import { OrientacaoSexualSelectField } from 'components/form/field/select/OrientacaoSexualSelectField'
import { TipoSanguineoSelectField } from 'components/form/field/select/TipoSanguineoSelectField'
import { SimNaoEnum, SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import { resolveValue } from 'components/form/final-form/useField'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { onlyNumbers } from 'util/mask'
import { MetaPath } from 'util/metaPath'
import { createValidator, ErrorObject, length, required } from 'util/validation'

import { InformacoesComplementaresFormModel } from '../types/CidadaoFormModel'

export const validate = createValidator<InformacoesComplementaresFormModel>(
  {
    nis: [length(11)],
  },
  (values: InformacoesComplementaresFormModel, errors: ErrorObject<InformacoesComplementaresFormModel>) => {
    if (values == null) {
      return errors
    }

    if (values.desejaInformarOrientacaoSexual === SimNaoEnum.SIM) {
      errors.orientacaoSexual = required(values.orientacaoSexual)
    }
    if (values.desejaInformarIdentidadeGenero === SimNaoEnum.SIM) {
      errors.identidadeGenero = required(values.identidadeGenero)
    }
  }
)

export interface InformacoesComplementaresFieldGroupProps {
  name: MetaPath<InformacoesComplementaresFormModel>
  formProps: FormRenderProps<any>
}

export function InformacoesComplementaresFieldGroup(props: InformacoesComplementaresFieldGroupProps) {
  const { name } = props

  const theme = useTheme()
  return (
    <Grid wrap>
      <Cell sm={3} xs={12}>
        <TextField name={name.nis} label='Nº NIS (PIS/PASEP)' parse={onlyNumbers} maxLength={11} />
      </Cell>
      <Cell sm={3} xs={12}>
        <EstadoCivilSelectField name={name.estadoCivil} label='Estado civil' />
      </Cell>
      <Cell sm={3} xs={12}>
        <TipoSanguineoSelectField name={name.tipoSanguineo} label='Tipo sanguíneo' />
      </Cell>
      <Cell sm={6} xs={12}>
        <CboSelectField name={name.ocupacao} label='Ocupação' mostrarIndisponivelLotacao={true} />
      </Cell>
      <Cell sm={3} xs={12}>
        <EscolaridadeSelectField name={name.escolaridade} label='Escolaridade' />
      </Cell>
      <Cell sm={6} xs={12}>
        <VFlow vSpacing={0}>
          <HFlow justifyContent='space-between'>
            <Text>O cidadão deseja informar orientação sexual?</Text>
            <HFlow style={{ marginBottom: '0.25rem' }}>
              <SimNaoRadioGroupField name={name.desejaInformarOrientacaoSexual} clearable />
            </HFlow>
          </HFlow>

          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const informacoesComplementares = resolveValue(values, name) || ({} as InformacoesComplementaresFormModel)
              return (
                informacoesComplementares?.desejaInformarOrientacaoSexual === SimNaoEnum.SIM && (
                  <div style={{ backgroundColor: theme.pallete.gray.c90, padding: '0.5rem' }}>
                    <div>
                      <OrientacaoSexualSelectField
                        name={name.orientacaoSexual}
                        label='Qual a orientação sexual?'
                        style={{ width: 256 }}
                        required
                      />
                    </div>
                  </div>
                )
              )
            }}
          </FormSpy>
        </VFlow>
      </Cell>
      <Cell sm={6} xs={12}></Cell>
      <Cell sm={6} xs={12}>
        <VFlow vSpacing={0}>
          <HFlow justifyContent='space-between'>
            <Text>O cidadão deseja informar identidade de gênero?</Text>
            <HFlow style={{ marginBottom: '0.25rem' }}>
              <SimNaoRadioGroupField name={name.desejaInformarIdentidadeGenero} clearable />
            </HFlow>
          </HFlow>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => {
              const informacoesComplementares = resolveValue(values, name) || ({} as InformacoesComplementaresFormModel)
              return (
                informacoesComplementares?.desejaInformarIdentidadeGenero === SimNaoEnum.SIM && (
                  <div style={{ backgroundColor: theme.pallete.gray.c90, padding: '0.5rem' }}>
                    <div>
                      <IdentidadeGeneroSelectField
                        name={name.identidadeGenero}
                        label='Qual a identidade de gênero?'
                        style={{ width: 256 }}
                        required
                      />
                    </div>
                  </div>
                )
              )
            }}
          </FormSpy>
        </VFlow>
      </Cell>
    </Grid>
  )
}
