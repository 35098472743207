import { Cell, Grid, Heading, HeadingSection, HFlow, Icon, InfoLabel, Text, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Cep, Cpf, NumeroDeclaracaoObito, Telefone } from 'components/label'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import React from 'react'
import { identidadeGenero, nacionalidade, orientacaoSexual, sexo, tipoSanguineo } from 'types/enums'
import { capitalize, titleCase } from 'util/strConversor'

import { CidadaoTabSectionModel } from '../types/CidadaoTabSectionModel'

export interface CidadaoInformacoesViewProps {
  cidadao: CidadaoTabSectionModel
  mostrarProntuario?: boolean
  renderTipoLogradouro?: boolean
}

export const CidadaoInformacoesView = (props: CidadaoInformacoesViewProps) => {
  const { cidadao, mostrarProntuario } = props
  return (
    <VFlow vSpacing={2} style={{ paddingTop: '0.9375rem' }}>
      {cidadao?.faleceu && <GrupoObito cidadao={cidadao} />}
      <DadosPessoaisSection cidadao={cidadao} />
      <EquipeResponsavelSection cidadao={cidadao} />
      {!cidadao?.paisExterior && <EnderecoSection cidadao={cidadao} renderTipoLogradouro />}
      {cidadao?.paisExterior && <EnderecoExteriorSection cidadao={cidadao} />}
      <ContatosSection cidadao={cidadao} />
      <InformacoesSociodemograficasSection cidadao={cidadao} />
      {mostrarProntuario && <CompartilhamentoProntuarioSection cidadao={cidadao} />}
    </VFlow>
  )
}

const GrupoObito = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <Box>
      <Grid>
        <Cell style={{ width: '12rem' }}>
          <Heading color='normal' level={3}>
            Cidadão em óbito
          </Heading>
        </Cell>
        <Cell size={2} data-testid='CidadaoInformacoesGerais.dataObito'>
          <InfoLabel title='Data de óbito'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataObito} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.numeroDeclaracaoObito'>
          <InfoLabel placeholder='-' title='Número da Declaração de Óbito'>
            <NumeroDeclaracaoObito value={cidadao?.numeroDocumentoObito} />
          </InfoLabel>
        </Cell>
      </Grid>
    </Box>
  )
}

const DadosPessoaisSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Dados pessoais'>
      <Grid wrap>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.cpf'>
          <InfoLabel title='CPF'>
            <Cpf value={cidadao?.cpf} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.cns'>
          <InfoLabel placeholder='-' title='CNS'>
            {cidadao?.cns}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.dataNascimento'>
          <InfoLabel placeholder='-' title='Data de nascimento'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataNascimento} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.sexo'>
          <InfoLabel placeholder='-' title='Sexo'>
            {sexo[cidadao?.sexo]}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.racaCor'>
          <InfoLabel placeholder='-' title='Raça/cor'>
            {titleCase(cidadao?.racaCor?.nome)}
          </InfoLabel>
        </Cell>
        {cidadao?.racaCor?.racaCorDbEnum === RacaCorDbEnum.INDIGENA ? (
          <Cell size={4} data-testid='CidadaoInformacoesGerais.etnia'>
            <InfoLabel placeholder='-' title='Etnia'>
              {titleCase(cidadao?.etnia?.nome)}
            </InfoLabel>
          </Cell>
        ) : (
          <Cell size={4} />
        )}
        <Cell size={4} data-testid='CidadaoInformacoesGerais.nomeMae'>
          <InfoLabel placeholder='-' title='Nome da mãe'>
            {titleCase(cidadao?.nomeMae)}
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.nomePai'>
          <InfoLabel placeholder='-' title='Nome do pai'>
            {titleCase(cidadao?.nomePai)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.nacionalidade'>
          <InfoLabel placeholder='-' title='Nacionalidade'>
            {nacionalidade[cidadao?.nacionalidade?.nacionalidadeDbEnum]}
          </InfoLabel>
        </Cell>
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.BRASILEIRA && (
          <Cell size={8} data-testid='CidadaoInformacoesGerais.municipioNascimento'>
            <InfoLabel placeholder='-' title='Município de nascimento'>
              {titleCase(cidadao?.localidadeNascimento?.nome) + ' - ' + cidadao?.localidadeNascimento?.uf.sigla}
            </InfoLabel>
          </Cell>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.NATURALIZADA && (
          <>
            <Cell size={4} data-testid='CidadaoInformacoesGerais.portariaNaturalizacao'>
              <InfoLabel placeholder='-' title='Portaria de naturalização'>
                {titleCase(cidadao?.portariaNaturalizacao)}
              </InfoLabel>
            </Cell>

            <Cell size={4} data-testid='CidadaoInformacoesGerais.dataNaturalizacao'>
              <InfoLabel placeholder='-' title='Data de naturalização'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataNaturalizacao} />
              </InfoLabel>
            </Cell>
          </>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.ESTRANGEIRA && (
          <>
            <Cell size={4} data-testid='CidadaoInformacoesGerais.paisNascimento'>
              <InfoLabel placeholder='-' title='País de nascimento'>
                {titleCase(cidadao?.paisNascimento?.nome)}
              </InfoLabel>
            </Cell>

            <Cell size={4} data-testid='CidadaoInformacoesGerais.dataEntradaBrasil'>
              <InfoLabel placeholder='-' title='Data de entrada no Brasil'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataEntradaBrasil} />
              </InfoLabel>
            </Cell>
          </>
        )}
      </Grid>
    </HeadingSection>
  )
}

const EquipeResponsavelSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Equipe responsável pelo cidadão'>
      <Grid wrap>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.equipeResponsavel'>
          <InfoLabel placeholder='-' title='Equipe responsável'>
            {cidadao?.cidadaoVinculacaoEquipe?.equipe &&
              titleCase(
                `${cidadao?.cidadaoVinculacaoEquipe?.equipe.nome} - ${cidadao?.cidadaoVinculacaoEquipe?.equipe.ine}`
              )}
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.unidadeResponsavel'>
          <InfoLabel placeholder='-' title='Unidade responsável'>
            {titleCase(cidadao?.cidadaoVinculacaoEquipe?.unidadeSaude?.nome)}
          </InfoLabel>
        </Cell>
        {cidadao?.cidadaoVinculacaoEquipe?.utilizarCadastroIndividual && (
          <Cell size={8} data-testid='CidadaoInformacoesGerais.utilizarCadastroIndividual'>
            <Text>Utilizando a informação do cadastro individual do cidadão</Text>
          </Cell>
        )}
      </Grid>
    </HeadingSection>
  )
}

const EnderecoSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Endereço'>
      <Grid wrap>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.paisResidencia'>
          <InfoLabel placeholder='-' title='País de residência'>
            Brasil
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.cep'>
          <InfoLabel placeholder='-' title='CEP'>
            <Cep value={cidadao?.endereco?.cep} />
          </InfoLabel>
        </Cell>

        <Cell size={4} data-testid='CidadaoInformacoesGerais.estado'>
          <InfoLabel placeholder='-' title='Estado'>
            {titleCase(cidadao?.endereco?.uf?.nome)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.municipio'>
          <InfoLabel placeholder='-' title='Município'>
            {titleCase(cidadao?.endereco?.municipio?.nome)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.bairro'>
          <InfoLabel placeholder='-' title='Bairro'>
            {titleCase(cidadao?.endereco?.bairro)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.tipoLogradouro'>
          <InfoLabel placeholder='-' title='Tipo de logradouro'>
            {titleCase(cidadao?.endereco?.tipoLogradouro?.nome)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.logradouro'>
          <InfoLabel placeholder='-' title='Logradouro'>
            {titleCase(cidadao?.endereco?.logradouro)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.numero'>
          <InfoLabel placeholder='-' title='Número'>
            {cidadao?.endereco?.numero?.toUpperCase()}
          </InfoLabel>
        </Cell>

        <Cell size={4} data-testid='CidadaoInformacoesGerais.complemento'>
          <InfoLabel placeholder='-' title='Complemento'>
            {titleCase(cidadao?.endereco?.complemento)}
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.pontoReferencia'>
          <InfoLabel placeholder='-' title='Ponto de referência'>
            {capitalize(cidadao?.endereco?.pontoReferencia)}
          </InfoLabel>
        </Cell>

        <Cell size={4} data-testid='CidadaoInformacoesGerais.area'>
          <InfoLabel placeholder='-' title='Área'>
            {cidadao?.area}
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.microArea'>
          <InfoLabel placeholder='-' title='Microárea'>
            {cidadao?.microArea}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const EnderecoExteriorSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Endereço'>
      <Grid wrap>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.paisResidenciaExterior'>
          <InfoLabel placeholder='-' title='País de residência'>
            {titleCase(cidadao?.paisExterior.nome)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.municipioResidenciaExterior'>
          <InfoLabel placeholder='-' title='Município de residência'>
            {titleCase(cidadao?.localidadeExterior)}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const ContatosSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Contatos'>
      <Grid wrap>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.telefoneResidencial'>
          <InfoLabel placeholder='-' title='Telefone residencial'>
            <Telefone value={cidadao?.telefoneResidencial} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.telefoneCelular'>
          <InfoLabel placeholder='-' title='Telefone celular'>
            <Telefone value={cidadao?.telefoneCelular} />
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.telefoneContato'>
          <InfoLabel placeholder='-' title='Telefone de contato'>
            <Telefone value={cidadao?.telefoneContato} />
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.email'>
          <InfoLabel placeholder='-' title='Email'>
            {cidadao?.email}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const InformacoesSociodemograficasSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection level={2} title='Informações sociodemogŕaficas'>
      <Grid wrap>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.nPIS'>
          <InfoLabel placeholder='-' title='Nº NIS (PIS/PASEP)'>
            {cidadao?.nisPisPasep}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.estadoCivil'>
          <InfoLabel placeholder='-' title='Estado civil'>
            {titleCase(cidadao?.estadoCivil?.nome)}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.tipoSanguineo'>
          <InfoLabel placeholder='-' title='Tipo sanguíneo'>
            {tipoSanguineo[cidadao?.tipoSanguineo]}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.ocupacao'>
          <InfoLabel placeholder='-' title='Ocupação'>
            {titleCase(cidadao?.cbo?.nome)}
          </InfoLabel>
        </Cell>
        <Cell size={8} data-testid='CidadaoInformacoesGerais.escolaridade'>
          <InfoLabel placeholder='-' title='Escolaridade'>
            {cidadao?.escolaridade?.nome}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.orientacaoSexual'>
          <InfoLabel placeholder='-' title='Orientação sexual'>
            {orientacaoSexual[cidadao?.orientacaoSexualDbEnum]}
          </InfoLabel>
        </Cell>
        <Cell size={4} data-testid='CidadaoInformacoesGerais.identidadeGenero'>
          <InfoLabel placeholder='-' title='Identidade de gênero'>
            {identidadeGenero[cidadao?.identidadeGeneroDbEnum]}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}

const CompartilhamentoProntuarioSection = (props: CidadaoInformacoesViewProps) => {
  const { cidadao } = props
  return (
    <HeadingSection style={{ marginBottom: '5rem' }} level={2} title='Compartilhamento de prontuário'>
      <Grid wrap>
        <Cell size={4}>
          <Text>
            Por padrão, o prontuário do cidadão é visível por todas as unidades de saúde de uma mesma instalação do
            e-SUS APS, para agilizar o atendimento em toda a rede.
          </Text>
        </Cell>
        <Cell size={8}></Cell>
        <Cell size={4}>
          <HFlow alignItems='center' hSpacing={0.5} data-testid='CidadaoInformacoesGerais.compartilhamentoProntuario'>
            {cidadao?.stCompartilhaProntuario ? (
              <>
                <Icon icon='checkCircleOutline' size={1} fill='success' />
                <Text color='success'>Compartilhamento de prontuário ativo</Text>
              </>
            ) : (
              <>
                <Icon icon='banOutline' size={1} fill='danger' />
                <Text color='danger'>Compartilhamento de prontuário inativo</Text>
              </>
            )}
          </HFlow>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}
