import { isEqual, startOfDay, toDate } from 'date-fns'

import { HistoricoAtendimentoModel } from '../model/historicoModel'

/**
 * Funcao utilizada para controlar quais atendimentos terao datas apresentadas,
 * controlando o formato de linha do tempo na tabela do historico.
 *
 * @param atendimentos os atendimentos da pagina da tabela
 * @param sortDirection a ordenacao das listas por data - ASC | DESC
 * @returns uma lista com a prop "showDataAtendimento" ajustada para o controle da linha do tempo
 */
const atendimentosHistoricoByDate = (atendimentos: HistoricoAtendimentoModel[]): HistoricoAtendimentoModel[] => {
  let prev: Date = null

  return atendimentos.map((atend) => {
    const dtAtend = startOfDay(toDate(atend.dataAtendimento))
    if (!prev || !isEqual(prev, dtAtend)) {
      prev = dtAtend
      return { ...atend, showDataAtendimento: true }
    } else {
      return { ...atend, showDataAtendimento: false }
    }
  })
}

export { atendimentosHistoricoByDate }
