import { HFlow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { AsyncSelectQueryFieldProps, SelectField } from 'components/form'
import { css } from 'emotion'
import { LoteImunobiologicoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  LoteImunobiologicoSelectFieldQuery,
  LoteImunobiologicoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export interface LoteImunobiologicoSelectModel {
  id: string
  lote?: string
  validade?: LocalDate
  fabricante?: { id: string; nome: string }
}

export interface LoteImunobiologicoSelectFieldProps
  extends AsyncSelectQueryFieldProps<
    LoteImunobiologicoSelectModel,
    LoteImunobiologicoSelectFieldQuery,
    LoteImunobiologicoSelectFieldQueryVariables
  > {
  mostrarInativos?: boolean
  imunobiologicoId?: ID
}

const extractItems = (data: LoteImunobiologicoSelectFieldQuery) => {
  return data?.lotesImunobiologico?.content
}

const handleItemToString = (item: LoteImunobiologicoSelectModel) => {
  return item && item.lote + ' - ' + item.fabricante?.nome
}

const renderItem = (item: LoteImunobiologicoSelectModel) => {
  return (
    item && (
      <VFlow vSpacing={0}>
        <HFlow hSpacing={0.5}>
          <Text
            style={css`
              word-break: break-all;
            `}
          >
            <strong>{item.lote}</strong>
            {' - ' + item.fabricante.nome}
          </Text>
        </HFlow>
        <HFlow hSpacing={0.5}>
          <strong>Validade:</strong>
          <DateTime value={item.validade} format='DD/MM/YYYY' />
        </HFlow>
      </VFlow>
    )
  )
}

export function LoteImunobiologicoSelectField(props: LoteImunobiologicoSelectFieldProps) {
  const { mostrarInativos, imunobiologicoId, ...rest } = props
  const variables = (input: string): LoteImunobiologicoSelectFieldQueryVariables => ({
    input: {
      filtroImunoFabricante: input,
      mostrarLotesInativos: !!mostrarInativos,
      imunobiologicoId: imunobiologicoId,
      pageParams: {
        size: DEFAULT_SELECT_SIZE,
      },
    },
  })

  const { ...asyncProps } = useAsyncQuerySelect<
    LoteImunobiologicoSelectModel,
    LoteImunobiologicoSelectFieldQuery,
    LoteImunobiologicoSelectFieldQueryVariables
  >({
    query: LoteImunobiologicoSelectFieldDocument,
    extractItems: extractItems,
    variables,
  })

  return (
    <SelectField<LoteImunobiologicoSelectModel>
      renderItem={renderItem}
      itemToString={handleItemToString}
      {...asyncProps}
      {...rest}
    />
  )
}
