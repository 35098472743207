/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HeadingSection, HFlow, VFlow } from 'bold-ui'
import { blue, gray, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { Box } from 'components/Box'
import { ErrorField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { usePecField } from 'components/form/final-form/useField'
import theme from 'config/theme'
import { ClassificacaoRisco } from 'graphql/types.generated'
import { CSSProperties } from 'react'
import { MetaPath } from 'util/metaPath'

export interface ClassificacaoRiscoButtonGroupProps {
  name: MetaPath<ClassificacaoRisco>
}

export function ClassificacaoRiscoButtonGroup({ name }: ClassificacaoRiscoButtonGroupProps) {
  const { meta } = usePecField({ name })
  const classes = createStyles()

  return (
    <HeadingSection level={3} vSpace={8} title={<RequiredLabel label='Classificação de risco/Vulnerabilidade' />}>
      <Box style={meta.submitFailed && meta.error ? classes.box : null}>
        <HFlow hSpacing={3.5}>
          <VFlow>
            <div css={classes.container}>Não aguda</div>
            <RadioFieldContainer
              name={name}
              value={ClassificacaoRisco.PEC_NAO_AGUDA}
              style={{ ...classes.card, ...classes.naoAguda }}
              label='Não aguda'
            />
          </VFlow>
          <VFlow>
            <div css={classes.container}>Aguda</div>
            <HFlow hSpacing={1.5}>
              <RadioFieldContainer
                name={name}
                value={ClassificacaoRisco.PEC_BAIXA}
                style={{ ...classes.card, ...classes.baixa }}
                label='Baixa'
              />
              <RadioFieldContainer
                name={name}
                value={ClassificacaoRisco.PEC_INTERMEDIARIA}
                style={{ ...classes.card, ...classes.intermediaria }}
                label='Intermediária'
              />
              <RadioFieldContainer
                name={name}
                value={ClassificacaoRisco.PEC_ALTA}
                style={{ ...classes.card, ...classes.alta }}
                label='Alta'
              />
            </HFlow>
          </VFlow>
        </HFlow>
      </Box>
      <div
        css={css`
          padding-bottom: 1rem;
          padding-top: 0.5rem;
        `}
      >
        <ErrorField name={name} />
      </div>
    </HeadingSection>
  )
}

const createStyles = () => ({
  card: {
    width: '10rem',
    height: '4rem',
    fontWeight: 'bold',
    paddingTop: '1.2rem',
    paddingBottom: '1.2rem',
    '&:focus': {
      boxShadow: 'none',
    },
  } as CSSProperties,

  naoAguda: {
    background: blue.c90,
    color: blue.c40,
    border: '0.07rem solid' + blue.c40,
    '&:hover': {
      backgroundColor: blue.c80,
    },
  } as CSSProperties,

  baixa: {
    background: green.c90,
    color: green.c40,
    border: '0.07rem solid' + green.c40,
    '&:hover': {
      backgroundColor: green.c80,
    },
  } as CSSProperties,

  intermediaria: {
    background: yellow.c90,
    color: yellow.c40,
    border: '0.07rem solid' + yellow.c40,
    '&:hover': {
      backgroundColor: yellow.c80,
    },
  } as CSSProperties,

  alta: {
    background: red.c90,
    color: red.c40,
    border: '0.07rem solid' + red.c40,
    '&:hover': {
      backgroundColor: red.c80,
    },
  } as CSSProperties,

  container: css`
    text-align: center;
    background-color: ${gray.c90};
    border: 0rem;
    padding: 0.2rem;
  `,

  box: css`
    border: solid 1px ${theme.pallete.status.danger.main};
  `,
})
