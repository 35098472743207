import { SelectField, SelectFieldProps } from 'components/form'
import { LocalAplicacaoSelectFieldDocument } from 'graphql/hooks.generated'
import { LocalAplicacaoSelectFieldQuery, LocalAplicacaoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type LocalAplicacaoSelectFieldModel = LocalAplicacaoSelectFieldQuery['locaisAplicacao'][0]

export interface LocalAplicacaoSelectFieldProps
  extends Omit<SelectFieldProps<LocalAplicacaoSelectFieldModel>, 'items' | 'itemToString'> {
  viaAdministracaoId?: ID
}

export function LocalAplicacaoSelectField(props: LocalAplicacaoSelectFieldProps) {
  const { viaAdministracaoId, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    LocalAplicacaoSelectFieldModel,
    LocalAplicacaoSelectFieldQuery,
    LocalAplicacaoSelectFieldQueryVariables
  >({
    query: LocalAplicacaoSelectFieldDocument,
    extractItems: (data) => data && data.locaisAplicacao,
    variables: (inputQuery) => ({
      input: {
        viaAdministracaoId,
        query: inputQuery,
      },
    }),
    fetchPolicy: 'cache-first',
  })

  const itemToString = (item: LocalAplicacaoSelectFieldModel) => item && item.nome

  return <SelectField<LocalAplicacaoSelectFieldModel> itemToString={itemToString} {...asyncProps} {...rest} />
}
