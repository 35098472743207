import { AsyncSelectQueryFieldProps, SelectField } from 'components/form'
import { ViaAdministracaoVacinaSelectFieldDocument } from 'graphql/hooks.generated'
import {
  ViaAdministracaoVacinaSelectFieldQuery,
  ViaAdministracaoVacinaSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type ViaAdministracaoVacinaSelectModel = ViaAdministracaoVacinaSelectFieldQuery['viasAdministracaoVacina'][0]

export interface ViaAdministracaoVacinaSelectFieldProps
  extends AsyncSelectQueryFieldProps<
    ViaAdministracaoVacinaSelectModel,
    ViaAdministracaoVacinaSelectFieldQuery,
    ViaAdministracaoVacinaSelectFieldQueryVariables
  > {}

const extractItems = (data: ViaAdministracaoVacinaSelectFieldQuery) => data?.viasAdministracaoVacina

const handleItemToString = (item: ViaAdministracaoVacinaSelectModel) => item?.nome

const variables = (inputQuery: string): ViaAdministracaoVacinaSelectFieldQueryVariables => ({
  inputQuery: inputQuery,
})

export function ViaAdministracaoVacinaSelectField(props: ViaAdministracaoVacinaSelectFieldProps) {
  const { ...asyncProps } = useAsyncQuerySelect<
    ViaAdministracaoVacinaSelectModel,
    ViaAdministracaoVacinaSelectFieldQuery,
    ViaAdministracaoVacinaSelectFieldQueryVariables
  >({
    query: ViaAdministracaoVacinaSelectFieldDocument,
    extractItems,
    variables,
    fetchPolicy: 'cache-first',
  })

  return <SelectField<ViaAdministracaoVacinaSelectModel> itemToString={handleItemToString} {...asyncProps} {...props} />
}
