import { Omit, TextArea as BoldTextArea, TextAreaProps as BoldTextAreaProps } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../useField'
import { getFieldError } from '../util'

export type TextAreaFieldProps = UseFieldProps<any> & Omit<BoldTextAreaProps, 'name'>

export function TextAreaField(props: TextAreaFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <BoldTextArea error={getFieldError(meta)} {...input} {...rest} />
}
