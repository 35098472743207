import { Button, HeadingSection, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { EnderecoSection } from 'components/endereco/EnderecoSection'
import { CheckboxField, Form, FormFooter, FormRenderProps, SubmitButton } from 'components/form'
import { alterarSenhaModalForm } from 'components/form/alterar-senha/AlterarSenhaModalForm'
import { AlterarSenhaModel } from 'components/form/alterar-senha/model'
import {
  useAlteraAdminMutation,
  useProfissionalAdminDetailQuery,
  useRedefinirSenhaAdminInstalacaoMutation,
} from 'graphql/hooks.generated'
import React from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'

import { ProfissionalAdminInformacoesSection } from './ProfissionalAdminInformacoesSection'

interface ProfissionalExistenteProps {
  contraChave: string
  cpf: string
}

interface ProfissionalExistenteFormModel {
  definirNovaSenha?: boolean
}

const path = metaPath<ProfissionalExistenteFormModel>()

export default function ResetInstaladorProfissionalExistenteComponent(props: ProfissionalExistenteProps) {
  const [alteraAdminMutation] = useAlteraAdminMutation()
  const [redefinirSenhaAdmin] = useRedefinirSenhaAdminInstalacaoMutation()
  const history = useHistory()
  const { data } = useProfissionalAdminDetailQuery({ variables: { input: { ...props } } })
  const alert = useAlert()
  const validationInput = {
    cpf: props.cpf,
    contraChave: props.contraChave,
  }

  const onSalvarClicked = async (values: ProfissionalExistenteFormModel) => {
    let confirmed = !values.definirNovaSenha

    if (values.definirNovaSenha) {
      confirmed = await alterarSenhaModalForm({
        onSubmit: (values: AlterarSenhaModel) => {
          return redefinirSenhaAdmin({
            variables: {
              input: {
                novaSenhaInput: values.novaSenhaInput,
                cpf: props.cpf,
                contraChave: props.contraChave,
              },
            },
          })
        },
      })()
    }

    if (!confirmed) return

    await alteraAdminMutation({ variables: { input: { ...validationInput } } })
    alert('success', 'Administrador da instalação salvo com sucesso.')
    navigateHome()
  }

  const navigateHome = () => history.push('/')

  const onVoltarClicked = () => history.push('/reset/chave/0')

  if (!data || !data.profissionalByCpfContraChave) {
    return null
  }

  const renderForm = (formProps: FormRenderProps<ProfissionalExistenteFormModel>) => {
    return (
      <form noValidate>
        <VFlow vSpacing={2}>
          <ProfissionalAdminInformacoesSection profissional={data.profissionalByCpfContraChave} />
          <EnderecoSection endereco={data.profissionalByCpfContraChave.endereco} title='Endereço residencial' />

          <HeadingSection level={2} title='Redefinir senha'>
            <CheckboxField name={path.definirNovaSenha} label='Redefinir a senha atual' />
          </HeadingSection>

          <FormFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button onClick={onVoltarClicked} data-testid='FormFooter.voltar'>
                Voltar
              </Button>
            </div>
            <div>
              <Button onClick={navigateHome} style={{ marginRight: '1rem' }} data-testid='FormFooter.cancelar'>
                Cancelar
              </Button>
              <SubmitButton handleSubmit={formProps.handleSubmit} data-testid='FormFooter.salvar'>
                Salvar
              </SubmitButton>
            </div>
          </FormFooter>
        </VFlow>
      </form>
    )
  }

  return <Form<ProfissionalExistenteFormModel> render={renderForm} onSubmit={onSalvarClicked} />
}
