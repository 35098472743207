import { Cell, FormControl, Grid, HFlow, VFlow } from 'bold-ui'
import { CheckboxField, ErrorField } from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import { VigilanciaEmSaudeBucalEnum } from 'graphql/types.generated'
import React from 'react'
import { useFormState } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

export interface VigilanciaFieldProps {
  name: MetaPath<VigilanciaEmSaudeBucalEnum[]>
}

export function VigilanciaField(props: VigilanciaFieldProps) {
  const { values: formValues } = useFormState({ subscription: { values: true } })
  const value = resolveValue<VigilanciaEmSaudeBucalEnum[]>(formValues, props.name)
  const disabled = value && value.includes(VigilanciaEmSaudeBucalEnum.NAO_IDENTIFICADO)

  return (
    <VFlow>
      <FormControl label='Vigilância em saúde bucal' required>
        <VFlow vSpacing={0.5}>
          <ErrorField name={props.name} />

          <HFlow style={{ gridAutoColumns: 'auto', marginLeft: '-0.25rem' }}>
            <Grid alignItems='baseline' gapVertical={0.5}>
              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Abscesso dentoalveolar'
                  value={VigilanciaEmSaudeBucalEnum.ABSCESSO_DENTO_ALVEOLAR}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Alteração em tecidos moles'
                  value={VigilanciaEmSaudeBucalEnum.ALTERACAO_EM_TECIDOS_MOLES}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Dor de dente'
                  value={VigilanciaEmSaudeBucalEnum.DOR_DE_DENTE}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Fendas ou fissuras lábio palatais'
                  value={VigilanciaEmSaudeBucalEnum.FENDAS_OU_FISSURAS_LABIO_PALATAIS}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Fluorose dentária moderada ou severa'
                  value={VigilanciaEmSaudeBucalEnum.FLUOROSE_DENTARIA_MODERADA_OU_SEVERA}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Traumatismo dentoalveolar'
                  value={VigilanciaEmSaudeBucalEnum.TRAUMATISMO_DENTO_ALVEOLAR}
                  disabled={disabled}
                />
              </Cell>

              <Cell size={4}>
                <CheckboxField
                  name={props.name}
                  label='Não identificado'
                  value={VigilanciaEmSaudeBucalEnum.NAO_IDENTIFICADO}
                  disabled={value && value.length > 0 && !value.includes(VigilanciaEmSaudeBucalEnum.NAO_IDENTIFICADO)}
                />
              </Cell>
            </Grid>
          </HFlow>
        </VFlow>
      </FormControl>
    </VFlow>
  )
}
