import { Text, VFlow } from 'bold-ui'
import { resolveValue, usePecField } from 'components/form/final-form/useField'
import React, { useCallback } from 'react'
import { useFormState } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { AgendamentoDia } from '../types/AgendamentoDia'
import { AgendamentosDiaCheckField } from './AgendamentosDiaCheckField'

export interface AgendamentosDiaFieldProps {
  name: MetaPath<string>
  agendamentosDia?: AgendamentoDia[]
}

export function AgendamentosDiaField(props: AgendamentosDiaFieldProps) {
  const { name, agendamentosDia } = props
  const {
    input: { onChange: setValue },
  } = usePecField({ name })

  const { values: formValues } = useFormState({ subscription: { values: true } })
  const currentValue = resolveValue(formValues, name)

  const handleChange = useCallback(
    (value: string) => {
      const actualValue = value === currentValue ? undefined : value
      setValue(actualValue)
    },
    [currentValue, setValue]
  )

  return (
    <>
      {!!agendamentosDia?.length && (
        <>
          <VFlow vSpacing={0.5}>
            <Text fontWeight='bold'>Próximos agendamentos do dia</Text>

            <VFlow>
              {agendamentosDia.map((agendamento: AgendamentoDia) => (
                <AgendamentosDiaCheckField
                  key={agendamento.id}
                  agendamento={agendamento}
                  onChange={handleChange}
                  checked={agendamento.id === currentValue}
                />
              ))}
            </VFlow>
          </VFlow>
        </>
      )}
    </>
  )
}
