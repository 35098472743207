import { Omit, SelectMenuItem, Text, VFlow } from 'bold-ui'
import { SelectDownshiftMenuProps } from 'bold-ui/lib/components/Select/SelectSingle/SelectDownshiftMenu'
import { blue } from 'bold-ui/lib/styles/colors'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import { CidSelectFieldDocument } from 'graphql/hooks.generated'
import { Cid10QueryInput, CidSelectFieldQuery, CidSelectFieldQueryVariables } from 'graphql/types.generated'
import React, { useMemo } from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type CidSelectFieldModel = CidSelectFieldQuery['cids']['content'][0]

export interface CidSelectFieldProps
  extends Omit<SelectFieldProps<CidSelectFieldModel>, 'items' | 'itemToString'>,
    Pick<Cid10QueryInput, 'sexo' | 'parteBucal' | 'idadeCidadaoEmAnos'> {
  ciapRelacionada?: ID
  renderGrupoCiapRelacionada?: boolean
}

export function CidSelectField(props: CidSelectFieldProps) {
  const { sexo, ciapRelacionada, renderGrupoCiapRelacionada = true, parteBucal, idadeCidadaoEmAnos, ...rest } = props
  const idGrupoCidsRelacionadas = 'grupoCidsRelacionadas'
  const idGrupoOutrasCids = 'grupoOutrasCids'

  const { items, ...asyncPropsRest } = useAsyncQuerySelect<
    CidSelectFieldModel,
    CidSelectFieldQuery,
    CidSelectFieldQueryVariables
  >({
    query: CidSelectFieldDocument,
    extractItems: (data) => data?.cids?.content,
    variables: (inputQuery: string): CidSelectFieldQueryVariables => ({
      input: {
        query: inputQuery,
        sexo,
        ciapRelacionada,
        parteBucal,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        idadeCidadaoEmAnos,
      },
      fetchCiapsRelacionadas: !!ciapRelacionada,
    }),
  })

  const selectItems = useMemo<{
    hasCidsRelacionadas: boolean
    hasOutrasCids: boolean
    items: CidSelectFieldModel[]
  }>(() => {
    items?.sort((a, b) => {
      return a.nome.localeCompare(b.nome)
    })

    const cidsRelacionadasComCiap: CidSelectFieldModel[] = [
      { id: idGrupoCidsRelacionadas, ciapsRelacionadas: [], codigo: '', nome: '' },
    ]
    const outrasCid: CidSelectFieldModel[] = [{ id: idGrupoOutrasCids, ciapsRelacionadas: [], codigo: '', nome: '' }]

    items?.forEach((item) => {
      item.ciapsRelacionadas?.map((value) => value.id).includes(ciapRelacionada)
        ? cidsRelacionadasComCiap.push(item)
        : outrasCid.push(item)
    })

    return {
      hasCidsRelacionadas: cidsRelacionadasComCiap.length > 1,
      hasOutrasCids: outrasCid.length > 1,
      items: cidsRelacionadasComCiap.concat(outrasCid),
    }
  }, [items, ciapRelacionada])

  const itemToString = (item: CidSelectFieldModel) => item && `${item.nome} - ${item.codigo}`

  const renderItem = (item: CidSelectFieldModel) => {
    return (
      <>
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>{item.nome}</Text>
          <HLabel title='Código '>{item.codigo}</HLabel>
        </VFlow>
      </>
    )
  }

  const Item = (itemProps: SelectDownshiftMenuProps<CidSelectFieldModel> & { item: CidSelectFieldModel }) => {
    const {
      downshift: { getItemProps },
      item,
      renderItem,
    } = itemProps

    const renderGrupoCidsRelacionadas = itemProps.item?.id === idGrupoCidsRelacionadas
    const renderGrupoOutrasCid = itemProps.item?.id === idGrupoOutrasCids

    if (!renderGrupoCiapRelacionada && !item.nome && !item.codigo) return <> </>

    return (
      <>
        {renderGrupoCidsRelacionadas || renderGrupoOutrasCid ? (
          <RenderGrupoHeader
            renderGrupoOutrasCid={renderGrupoOutrasCid}
            hasCiapSelecionada={ciapRelacionada}
            hasCidsRelacionadas={selectItems.hasCidsRelacionadas}
            hasOutrasCids={selectItems.hasOutrasCids}
          />
        ) : (
          <SelectMenuItem {...getItemProps({ item })}>{renderItem(item)}</SelectMenuItem>
        )}
      </>
    )
  }

  return (
    <SelectField<CidSelectFieldModel>
      itemToString={itemToString}
      items={selectItems.items}
      renderItem={renderItem}
      components={{
        Item,
      }}
      {...asyncPropsRest}
      {...rest}
    />
  )
}

interface GrupoHeaderProps {
  hasCiapSelecionada: boolean
  hasCidsRelacionadas: boolean
  hasOutrasCids: boolean
  renderGrupoOutrasCid: boolean
}

function RenderGrupoHeader(props: GrupoHeaderProps) {
  const { hasCiapSelecionada, renderGrupoOutrasCid, hasCidsRelacionadas, hasOutrasCids } = props

  const emptyGroupMessage = renderGrupoOutrasCid
    ? !hasOutrasCids && 'Nenhuma outra CID10.'
    : !hasCiapSelecionada
    ? 'Nenhuma CIAP2 selecionada.'
    : !hasCidsRelacionadas && 'Nenhuma CID10 relacionada.'

  return (
    <>
      <SelectMenuItem style={{ pointerEvents: 'none', cursor: 'not-allowed', backgroundColor: blue.c90 }}>
        <Text fontWeight='bold' style={{ color: blue.c40 }}>
          {!renderGrupoOutrasCid ? 'CID10 relacionadas com a CIAP2 selecionada' : 'Outras CID10'}
        </Text>
      </SelectMenuItem>
      {emptyGroupMessage && (
        <SelectMenuItem style={{ pointerEvents: 'none', cursor: 'not-allowed' }}>
          <Text>{emptyGroupMessage}</Text>
        </SelectMenuItem>
      )}
    </>
  )
}
