import { useCallback, useState } from 'react'

interface UseAccordionControlProps {
  allowMultipleExpanded?: boolean
}

export interface AccordionControls {
  /**
   * Permite verificar se um elemento estah expandido.
   * Essa funcao deve ser colocada na prop dangerouslySetExpanded de um AccordionItem.
   */
  isExpanded(id: string): boolean

  /**
   * Atualiza a lista de itens expandidos.
   * Essa funcao deve ser colocada na prop onClick de um AccordionItem.
   * @param id Id do item que foi clicado
   */
  handleAccordionItemClick(id: string): void

  /**
   * Limpa a lista de elementos expandidos
   */
  resetExpandedItems(): void
}

/**
 * Externaliza o controle dos itens expandidos do Accordion da react-acessible-accordion
 */
export function useAccordionControl(props: UseAccordionControlProps): AccordionControls {
  const { allowMultipleExpanded } = props

  const [expandedItems, setExpandedItems] = useState<string[]>([])

  const handleChange = useCallback(
    (id: string) => {
      setExpandedItems((prevExpandedItems) => {
        const index = prevExpandedItems.indexOf(id)

        if (index === -1) {
          return allowMultipleExpanded ? [...prevExpandedItems, id] : [id]
        } else {
          prevExpandedItems.splice(index, 1)
          return [...prevExpandedItems]
        }
      })
    },
    [allowMultipleExpanded]
  )

  const isExpanded = useCallback((id: string) => expandedItems.indexOf(id) !== -1, [expandedItems])

  return {
    handleAccordionItemClick: handleChange,
    resetExpandedItems: () => setExpandedItems([]),
    isExpanded,
  }
}
