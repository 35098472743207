import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { ProcedimentoOdontoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  ParteBucalEnum,
  Procedimento,
  ProcedimentoOdontoSelectFieldQuery,
  ProcedimentoOdontoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React, { useCallback } from 'react'

import { ProcedimentoSelectFieldItem } from '../ProcedimentoSelectField/ProcedimentoSelectFieldItem'

export type ProcedimentoOdontoSelectModel = Procedimento

export interface ProcedimentoOdontoSelectFieldProps
  extends Omit<SelectFieldProps<ProcedimentoOdontoSelectModel>, 'items' | 'itemToString'> {
  dataNascimento: LocalDate
  dataAtendimento: LocalDate
  parteBucal: ParteBucalEnum | null
  itemsDisabled?: ProcedimentoOdontoSelectModel[]
}

export function ProcedimentoOdontoSelectField(props: ProcedimentoOdontoSelectFieldProps) {
  const { dataNascimento, dataAtendimento, parteBucal, itemsDisabled, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    ProcedimentoOdontoSelectModel,
    ProcedimentoOdontoSelectFieldQuery,
    ProcedimentoOdontoSelectFieldQueryVariables
  >({
    query: ProcedimentoOdontoSelectFieldDocument,
    extractItems: (data) => data?.procedimentosEvolucaoOdonto.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        dataNascimento: dataNascimento,
        dataAtendimento: dataAtendimento,
        parteBucal: parteBucal,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  const itemIsDisabled = useCallback(
    (item: ProcedimentoOdontoSelectModel) => itemsDisabled?.map((itemDisabled) => itemDisabled?.id).includes(item.id),
    [itemsDisabled]
  )

  return (
    <SelectField<ProcedimentoOdontoSelectModel>
      itemToString={itemToString}
      renderItem={ProcedimentoSelectFieldItem}
      itemIsEqual={(a, b) => a.id === b.id}
      itemIsDisabled={itemIsDisabled}
      {...asyncProps}
      {...rest}
    />
  )
}

const itemToString = (item: ProcedimentoOdontoSelectModel) => item?.descricao
