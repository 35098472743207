import { MaskedTextField, MaskedTextFieldProps, Omit } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../useField'
import { getFieldError } from '../util'

export type MaskedFieldProps = UseFieldProps<string> & Omit<MaskedTextFieldProps, 'name'>

export function MaskedField(props: MaskedFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <MaskedTextField error={getFieldError(meta)} {...input} {...rest} />
}
