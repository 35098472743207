import { Checkbox, CheckboxProps, Omit } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../useField'

export type CheckboxFieldProps = UseFieldProps<any> & Omit<CheckboxProps, 'name'>

export function CheckboxField(props: CheckboxFieldProps) {
  const { input, meta, ...rest } = usePecField({ type: 'checkbox', ...props })

  return <Checkbox {...input} {...rest} />
}
