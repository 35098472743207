/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoVacinacaoPecQuery } from 'graphql/hooks.generated'
import { HistoricoVacinacaoPecQuery, TipoRegistroVacinacaoEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { HistoricoPecPanelProps } from 'view/atendimentos/detail/historico/model/historicoModel'
import { filterByRegistroAnterior } from 'view/atendimentos/detail/historico/utils/filterByRegistroAnterior'
import { mountInfosAdministrativasAtendimentosRecentes } from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoGapPanel from '../../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../../HistoricoNoDetailAvailable'
import CondicoesVacinacao from '../CondicoesVacinacao'
import HistoricoVacinacaoPecTableItem from './HistoricoVacinacaoPecTableItem'

export type HistoricoVacinacaoPecModel = HistoricoVacinacaoPecQuery['historicoVacinacaoPec']['registrosVacinacao'][0]

export interface HistoricoVacinacaoRecentesProps extends HistoricoPecPanelProps {}

export default function HistoricoVacinacaoPec(props: HistoricoVacinacaoRecentesProps) {
  const { idAtend, idadeOrigemAtendimento } = props
  const {
    data: { historicoVacinacaoPec: historico },
    loading,
  } = useHistoricoVacinacaoPecQuery({
    variables: {
      input: {
        idAtend,
        isOrigemPec: true,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  const { recentes, registroAnterior } = filterByRegistroAnterior<HistoricoVacinacaoPecModel>(
    historico?.registrosVacinacao?.filter(
      (item) => item.tipoRegistroVacinacao.identificador === TipoRegistroVacinacaoEnum.APLICACAO
    ),
    'isRegistroAnterior'
  )

  const renderItem = (item: HistoricoVacinacaoPecModel) => HistoricoVacinacaoPecTableItem(item)

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativasAtendimentosRecentes(historico.atendimentoProfissional)}
    >
      {historico && (
        <VFlow>
          <CondicoesVacinacao
            gestante={historico.isGestante}
            puerpera={historico.isPuerpera}
            viajante={historico.isViajante}
            comunicanteHanseniase={historico.isComunicanteHanseniase}
          />
          <VFlow vSpacing={0.5}>
            <Heading level={3}>Vacinação</Heading>
            {!isEmpty(recentes) && (
              <DataTable<HistoricoVacinacaoPecModel>
                rows={recentes}
                columns={[
                  {
                    name: 'registro de aplicação',
                    header: 'Registro de aplicação',
                    render: renderItem,
                    style: css`
                      width: 40%;
                    `,
                  },
                ]}
              />
            )}
            {!isEmpty(registroAnterior) && (
              <DataTable<HistoricoVacinacaoPecModel>
                rows={registroAnterior}
                columns={[
                  {
                    name: 'transcrição de caderneta',
                    header: 'Transcrição de caderneta',
                    render: renderItem,
                    style: css`
                      width: 40%;
                    `,
                  },
                ]}
              />
            )}
          </VFlow>
        </VFlow>
      )}
    </HistoricoGapPanel>
  )
}
