import { resolveName } from 'components/form/final-form/useField'
import { getIn } from 'final-form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

type NameTypes = MetaPath<any> | MetaPath<any>[] | RegExp
type CallbackType = ({ value, allValues, errors }) => void

interface Props {
  name: NameTypes
  callback: CallbackType
  checkIsValid?: boolean
}

const useOnBlur = (props: Props): void => {
  const { name, callback, checkIsValid = false } = props

  const { getState, getFieldState } = useForm()
  const { active } = useFormState({ subscription: { active: true } })

  const [willBlur, setWillBlur] = useState<string>()

  const listNames = useMemo(
    () =>
      !isRegex(name)
        ? (Array.isArray(name) ? name : [name]).map((listItem: MetaPath<any>) => resolveName(listItem))
        : [],
    [name]
  )

  const isValid = useCallback((name: string) => (checkIsValid ? getFieldState(name).valid : true), [
    checkIsValid,
    getFieldState,
  ])

  const getCallback = useCallback(() => {
    const { values, errors } = getState()
    const resolvedValue = getIn(values, willBlur)

    return {
      value: resolvedValue,
      allValues: values,
      errors: errors,
    }
  }, [getState, willBlur])

  useEffect(() => {
    if (active && ((isRegex(name) && active.match(name as RegExp)) || listNames.includes(active))) {
      setWillBlur(active)
    }
  }, [active, isValid, listNames, name])

  useEffect(() => {
    if (!active && !!willBlur && isValid(willBlur)) {
      const response = getCallback()

      callback(response)
      setWillBlur(undefined)
    }
  }, [active, callback, getCallback, isValid, willBlur])
}

const isRegex = (value: any) => value instanceof RegExp

export default useOnBlur
