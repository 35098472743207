/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, isEmpty, Text, VFlow } from 'bold-ui'
import { NeuroMarcoEvolucao, StatusAvaliadoEnum } from 'graphql/types.generated'
import { Dictionary, groupBy } from 'lodash'
import { Fragment } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import HistoricoDetailTable from 'view/atendimentos/detail/historico/components/table/HistoricoDetailTable'
import { marcosDesenvolvimentoStatusRecord } from 'view/atendimentos/detail/historico/model/historicoModel'
import { idadeMarcoAlcancadoStringMount } from 'view/atendimentos/detail/historico/utils/historicoUtils'

import { HistoricoSectionType } from '../../../../HistoricoSection'

interface HistoricoMarcosDesenvolvimentoTableBoxProps {
  sectionStyle: HistoricoSectionType
  marcosEvolucao: NeuroMarcoEvolucao[]
}

export default function HistoricoMarcosDesenvolvimentoTableBox(props: HistoricoMarcosDesenvolvimentoTableBoxProps) {
  const { sectionStyle, marcosEvolucao } = props

  const marcosFaixaEtaria: Dictionary<NeuroMarcoEvolucao[]> = groupBy(
    marcosEvolucao,
    'marcoDetalhe.faixaEtaria.descricao'
  )

  const marcosStatusSection = (status: StatusAvaliadoEnum, faixaEtaria: string) => {
    const marcos = marcosFaixaEtaria[faixaEtaria].filter(
      (marcoFaixaEtaria) => marcoFaixaEtaria.statusAvaliacao === status
    )

    const marcosFormated = marcos.map(
      (marco) =>
        `${marco.marcoDetalhe.titulo}${
          status === StatusAvaliadoEnum.PRESENTE_COM_ATRASO
            ? idadeMarcoAlcancadoStringMount(marco.anosIdadeRegistro, marco.mesesIdadeRegistro)
            : ''
        }`
    )

    return (
      <HFlow hSpacing={0.375} key={status}>
        {!isEmpty(marcos) && (
          <Text
            style={css`
              color: ${marcosDesenvolvimentoStatusRecord[status].cor};
            `}
            fontWeight='bold'
          >
            {`${marcosDesenvolvimentoStatusRecord[status].titulo}: `}
          </Text>
        )}
        {stringGrouper(...marcosFormated)}
      </HFlow>
    )
  }

  return (
    <HistoricoDetailTable title='Marcos de desenvolvimento' sectionStyle={sectionStyle}>
      <VFlow vSpacing={1}>
        {Object.keys(marcosFaixaEtaria).map((marcoFaixaEtaria) => (
          <Fragment key={marcoFaixaEtaria}>
            <Text fontWeight='bold'>{marcoFaixaEtaria}</Text>
            <VFlow vSpacing={0}>
              {Object.keys(StatusAvaliadoEnum).map((status) =>
                marcosStatusSection(StatusAvaliadoEnum[status], marcoFaixaEtaria)
              )}
            </VFlow>
          </Fragment>
        ))}
      </VFlow>
    </HistoricoDetailTable>
  )
}
