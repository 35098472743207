import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, VFlow } from 'bold-ui'
import React from 'react'

interface ConfirmarModalProps {
  closeModal(): void
  onClickConfirmarCidadao(): void
  open: boolean
  children: any
}

export default function ConfirmarModal({ closeModal, onClickConfirmarCidadao, open, children }: ConfirmarModalProps) {
  return (
    <Modal onClose={closeModal} open={open} style={{ width: '40rem' }}>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='questionMarkFilled' size={3} fill='info' />
            <VFlow vSpacing={0.5}>
              <Heading level={1}>Deseja unificar os cadastros?</Heading>
            </VFlow>
          </HFlow>
          <Text data-testid='Mensagem'>{children}</Text>
        </VFlow>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' size='medium' onClick={closeModal}>
            Cancelar
          </Button>
          <Button
            kind='primary'
            skin='default'
            size='medium'
            onClick={onClickConfirmarCidadao}
            data-testid='ContinuarButton'
          >
            Continuar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
