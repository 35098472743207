import { HFlow, Text } from 'bold-ui'
import React from 'react'

interface RequiredLabelProps {
  label: string
}

export function RequiredLabel(props: RequiredLabelProps) {
  return (
    <HFlow hSpacing={0.25} alignItems='center'>
      {props.label}
      <Text color='danger'>*</Text>
    </HFlow>
  )
}
