import { Button, Cell, HFlow, Icon } from 'bold-ui'
import { confirm } from 'components/modals/confirm'
import { useAtestadoModeloByIdLazyQuery } from 'graphql/hooks.generated'
import { AtestadoModeloInput } from 'graphql/types.generated'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { conformToMask } from 'react-text-mask'
import { masks } from 'util/mask'

import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'
import AtestadoEmBrancoForm from '../em-branco/AtestadoEmBrancoForm'
import AtestadoModeloModal, { AtestadoModeloModel } from './AtestadoModeloModal'

export interface AtestadoModeloPrescricaoViewProps {
  cidadao: CidadaoAtestado
  dataAtendimento: Instant
  idModelo: ID
  onSubmit(values: AtestadoModel): void
  onDelete(): void
  onUpdate(nomeModelo: string): void
}

export default function AtestadoModeloPrescricaoView(props: AtestadoModeloPrescricaoViewProps) {
  const { cidadao, dataAtendimento, idModelo, onSubmit, onDelete, onUpdate } = props
  const [isModeloOpen, setModeloOpen] = useState(false)
  const [isDirtForm, setIsDirtForm] = useState(false)

  const [executeQuery, { data, refetch }] = useAtestadoModeloByIdLazyQuery()

  useEffect(() => {
    executeQuery({ variables: { id: idModelo } })
  }, [executeQuery, idModelo])

  const parseDescricao = useCallback(() => {
    let descricao = data?.atestadoModelo.descricao
    const cidadaoNome = !cidadao.nomeSocial ? cidadao.nome : `${cidadao.nomeSocial} - (${cidadao.nome})`
    if (descricao) {
      descricao = descricao
        .replaceAll('[NOME]', cidadaoNome.titleCase())
        .replaceAll('[DATA]', moment(dataAtendimento).format('DD/MM/YYYY'))
        .replaceAll('[HORA]', moment(dataAtendimento).format('HH:mm'))

      if (cidadao.cpf || cidadao.cns)
        descricao = descricao.replaceAll(
          '[CPF/CNS]',
          cidadao.cpf
            ? 'CPF ' + conformToMask(cidadao.cpf, masks.cpf, { guide: false }).conformedValue
            : 'CNS ' + cidadao.cns
        )
    }
    return descricao
  }, [cidadao.cns, cidadao.cpf, cidadao.nome, cidadao.nomeSocial, data, dataAtendimento])

  const initialValue = { descricao: parseDescricao() } as AtestadoModel
  const initialValueModelo = convert(data?.atestadoModelo)

  const handleUpdate = (nomeModelo: string) => {
    onUpdate(nomeModelo)
    refetch()
  }

  const handleOpenModel = () => {
    if (isDirtForm) {
      confirm({
        title: 'Deseja editar o modelo de atestado?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, editar o modelo',
        onConfirm: () => {
          setModeloOpen(true)
          setIsDirtForm(false)
        },
      })()
    } else setModeloOpen(true)
  }

  return (
    <>
      <Cell size={8} alignSelf='flex-end'>
        <HFlow justifyContent='flex-end'>
          <Button size='small' kind='primary' skin='outline' onClick={handleOpenModel}>
            <Icon icon='penOutline' style={{ marginRight: '0.25rem' }} />
            Editar modelo
          </Button>
        </HFlow>
      </Cell>

      <Cell size={12}>
        <AtestadoEmBrancoForm onSubmit={onSubmit} initialValues={initialValue} isDirtForm={() => setIsDirtForm(true)} />
      </Cell>

      {isModeloOpen && (
        <AtestadoModeloModal
          onClose={() => setModeloOpen(false)}
          initialValues={initialValueModelo}
          onDelete={onDelete}
          onUpdate={(nomeModelo) => handleUpdate(nomeModelo)}
        />
      )}
    </>
  )
}

const convert = (modelo: AtestadoModeloInput): AtestadoModeloModel =>
  modelo && {
    id: modelo.id,
    nome: modelo.nome,
    descricao: modelo.descricao,
  }
