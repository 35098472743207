import { Button, Cell, FormControl, Grid } from 'bold-ui'
import { DateField, DecimalField, ErrorField, Form, FormPrompt, FormRenderProps } from 'components/form'
import { FormApi } from 'final-form'
import moment from 'moment'
import React from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, maxLength, range, required } from 'util/validation'
import { v4 as uuidv4 } from 'uuid'

export interface MedicaoAnteriorFormModel {
  id: ID
  data: string
  peso: string
  altura: string
  perimetroCefalico: string
  medicoes: { peso; altura; perimetroCefalico }
}

export const meta = metaPath<MedicaoAnteriorFormModel>()

export const createMedicoesAnterioresValidator = (dataNasc, dataAtend) =>
  createValidator<MedicaoAnteriorFormModel>(
    {
      data: [required],
      peso: [maxLength(7), range(0.5, 500)],
      altura: [maxLength(5), range(20, 250)],
      perimetroCefalico: [maxLength(6), range(10, 200)],
    },
    (values, error) => {
      if (values?.data && !values?.altura && !values?.perimetroCefalico && !values?.peso)
        error.medicoes = 'Pelo menos uma das medições deve ser preenchida.'

      if (values?.data && !moment(values.data).isBefore(moment(dataAtend).subtract(1, 'd')))
        error.data = 'A data da medição deve ser anterior à data do atendimento.'

      if (
        values?.data &&
        !moment(values.data).isAfter(
          moment(dataNasc).subtract(1, 'd').set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        )
      )
        error.data = 'A data da medição deve ser posterior à data de nascimento do cidadão.'

      return error
    }
  )

export interface MedicaoAnteriorFormProps {
  dataNascimento: LocalDate
  dataAtendimento: Instant
  onAddMedicaoAnterior: (medicao: MedicaoAnteriorFormModel) => void
}

export function MedicaoAnteriorForm(props: MedicaoAnteriorFormProps) {
  const { dataNascimento, dataAtendimento, onAddMedicaoAnterior } = props

  const handleFormSubmit = (values: MedicaoAnteriorFormModel, form: FormApi<MedicaoAnteriorFormModel>) => {
    values.id = uuidv4()
    onAddMedicaoAnterior(values)
    form.getRegisteredFields().forEach((field) => form.resetFieldState(field))
    setTimeout(form.reset)
  }

  const handleFormError = () => {}

  const renderForm = (formProps: FormRenderProps<MedicaoAnteriorFormModel>) => {
    const { handleSubmit } = formProps

    return (
      <>
        <FormPrompt />
        <Grid gap={1}>
          <Cell size={12}>
            <ErrorField type='alert' name={meta.medicoes} />
          </Cell>
          <Cell size={3}>
            <DateField
              name={meta.data}
              label='Data da medição anterior'
              minDate={moment(dataNascimento).toDate()}
              maxDate={moment(dataAtendimento).subtract(1, 'd').toDate()}
              required
            />
          </Cell>
          <Cell size={2}>
            <DecimalField name={meta.peso} label='Peso (kg)' maxLength={7} precisao={3} />
          </Cell>
          <Cell size={2}>
            <DecimalField name={meta.altura} label='Altura (cm)' maxLength={5} precisao={2} />
          </Cell>
          <Cell size={3}>
            <DecimalField name={meta.perimetroCefalico} label='Perímetro cefálico (cm)' maxLength={5} precisao={1} />
          </Cell>
          <Cell size={2}>
            <FormControl label={<span>&nbsp;</span>}>
              <Button kind='primary' onClick={handleSubmit} size='small'>
                Adicionar
              </Button>
            </FormControl>
          </Cell>
        </Grid>
      </>
    )
  }
  return (
    <Form<MedicaoAnteriorFormModel>
      render={renderForm}
      onSubmit={handleFormSubmit}
      onSubmitFailed={handleFormError}
      validate={createMedicoesAnterioresValidator(dataNascimento, dataAtendimento)}
    />
  )
}
