import {
  IdentidadeGeneroEnum,
  NacionalidadeEnum,
  OrientacaoSexualEnum,
  RacaCorDbEnum,
  SexoEnum,
  TipoSanguineoEnum,
} from 'graphql/types.generated'
import Permissions from 'types/Permissions'

export const visualizarCidadaoAndProntuarioPermissions = [
  Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao,
  Permissions.visualizarProntuario,
]
export const visualizarAgendaAndProntuarioPermissions = [Permissions.visualizarAgenda, Permissions.visualizarProntuario]

export interface CidadaoTabSectionModel {
  id: ID
  cpf?: string
  cns?: string
  nomeSocial?: string
  nome: string
  dataNascimento?: string
  sexo: SexoEnum
  racaCor: {
    id: string
    nome: string
    racaCorDbEnum: RacaCorDbEnum
  }
  etnia?: {
    id: string
    nome: string
  }
  nomeMae?: string
  nomePai?: string
  nacionalidade?: {
    id: string
    nacionalidadeDbEnum: NacionalidadeEnum
  }
  prontuario?: {
    id: ID
    gestacoes?: {
      id: string
      inicio: LocalDate
      fim?: LocalDate
    }[]
    preNatalAtivo?: {
      id: string
    }
    puerpera?: boolean
  }
  localidadeNascimento?: {
    id: string
    nome: string
    uf: {
      id: string
      sigla: string
    }
  }
  portariaNaturalizacao?: string
  dataNaturalizacao?: string
  paisNascimento?: {
    id: string
    nome: string
  }
  dataEntradaBrasil?: string
  faleceu?: boolean
  dataObito?: string
  numeroDocumentoObito?: string
  telefoneResidencial?: string
  telefoneCelular?: string
  telefoneContato?: string
  email?: string
  cidadaoVinculacaoEquipe?: {
    id?: string
    tpCdsOrigem?: string
    utilizarCadastroIndividual?: boolean
    unidadeSaude?: {
      id: string
      nome?: string
    }
    equipe?: {
      id: string
      nome?: string
      ine: string
    }
  }
  localidadeExterior?: string
  paisExterior?: {
    id: string
    nome: string
  }
  endereco?: {
    cep?: string
    bairro?: string
    logradouro?: string
    numero?: string
    semNumero?: boolean
    complemento?: string
    pontoReferencia?: string
    uf?: {
      id: string
      nome: string
    }
    municipio?: {
      id: string
      nome: string
    }
    tipoLogradouro?: {
      id: string
      nome: string
    }
  }
  area?: string
  microArea?: string
  nisPisPasep?: string
  estadoCivil?: {
    id: string
    nome: string
  }
  tipoSanguineo?: TipoSanguineoEnum
  escolaridade?: {
    id: string
    nome: string
  }
  orientacaoSexualDbEnum?: OrientacaoSexualEnum
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  ativo?: boolean
  unificado?: boolean
  stCompartilhaProntuario?: boolean
  possuiAgendamento?: boolean
  cbo?: {
    id: string
    nome: string
  }
  dataAtualizado?: string
}
