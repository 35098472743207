import { TableCell, Text, useTheme } from 'bold-ui'
import { resolveName } from 'components/form/final-form/useField'
import { getFieldError } from 'components/form/final-form/util'
import { ParteBucalEnum } from 'graphql/types.generated'
import React, { memo, useMemo } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { PeriogramaCompletoCondicao, PeriogramaCompletoTableInputValue } from '../model'
import { periogramaCompletoTableStyles } from '../periograma-completo-table-view/PeriogramaCompletoTableView'
import PeriogramaCompletoTableInput from './PeriogramaCompletoTableFieldInput'

interface PeriogramaCompletoTableFieldCellProps {
  name: MetaPath<PeriogramaCompletoTableInputValue>
  dente: ParteBucalEnum
  condicao: PeriogramaCompletoCondicao
  ausente: boolean
  pip: string
}

function PeriogramaCompletoTableFieldCell(props: PeriogramaCompletoTableFieldCellProps) {
  const { name, dente, condicao, ausente, pip } = props

  const theme = useTheme()
  const classes = useMemo(() => periogramaCompletoTableStyles(theme), [theme])

  const { meta } = useField(resolveName(name), {
    subscription: {
      touched: true,
      error: true,
      dirtySinceLastSubmit: true,
      submitError: true,
    },
  })

  const error = getFieldError(meta)

  return (
    <TableCell
      style={[
        classes.tableCell,
        classes.tableCellInput,
        error?.length > 0 && !ausente && classes.tableCellInputError,
        ausente && classes.tableCellAusente,
      ]}
    >
      {condicao === PeriogramaCompletoCondicao.PIP && !ausente ? (
        <Text>{pip}</Text>
      ) : ausente ? (
        <Text color='inherit'>–</Text>
      ) : (
        <PeriogramaCompletoTableInput name={name} dente={dente} condicao={condicao} error={error} />
      )}
    </TableCell>
  )
}

export default memo(
  PeriogramaCompletoTableFieldCell,
  (oldProps, newProps) => oldProps.ausente === newProps.ausente && oldProps.pip === newProps.pip
)
