import { Button, HFlow, Theme, Tooltip, useStyles } from 'bold-ui'
import { usePecField } from 'components/form/final-form/useField'
import { GrauSatisfacaoUsuario } from 'graphql/types.generated'
import { ReactComponent as IndiferenteIcon } from 'images/pesquisa-satisfacao/indiferente-cinza.svg'
import { ReactComponent as InsatisfeitoIcon } from 'images/pesquisa-satisfacao/insatisfeito-cinza.svg'
import { ReactComponent as MuitoInsatisfeitoIcon } from 'images/pesquisa-satisfacao/mt-insatisfeito-cinza.svg'
import { ReactComponent as MuitoSatisfeitoIcon } from 'images/pesquisa-satisfacao/mt-satisfeito-cinza.svg'
import { ReactComponent as SatisfeitoIcon } from 'images/pesquisa-satisfacao/satisfeito-cinza.svg'
import React from 'react'
import { MetaPath } from 'util/metaPath'

interface ButtonContainerProps {
  name: MetaPath<GrauSatisfacaoUsuario>
}

export const ButtonContainer = (props: ButtonContainerProps) => {
  const { name } = props
  const {
    input: { value, onChange },
  } = usePecField({ name })

  const onFaceClicked = (index: GrauSatisfacaoUsuario) => (event: any) => {
    onChange(index)
  }

  const { classes } = useStyles(createStyles)

  return (
    <HFlow>
      <Tooltip text='Muito insatisfeito'>
        <Button
          size='small'
          onClick={onFaceClicked(GrauSatisfacaoUsuario.MUITO_INSATISFEITO)}
          style={value === GrauSatisfacaoUsuario.MUITO_INSATISFEITO ? classes.botaoClicado : classes.botaoNormal}
        >
          <MuitoInsatisfeitoIcon />
        </Button>
      </Tooltip>
      <Tooltip text='Insatisfeito'>
        <Button
          size='small'
          onClick={onFaceClicked(GrauSatisfacaoUsuario.INSATISFEITO)}
          style={value === GrauSatisfacaoUsuario.INSATISFEITO ? classes.botaoClicado : classes.botaoNormal}
        >
          <InsatisfeitoIcon />
        </Button>
      </Tooltip>
      <Tooltip text='Indiferente'>
        <Button
          size='small'
          onClick={onFaceClicked(GrauSatisfacaoUsuario.INDIFERENTE)}
          style={value === GrauSatisfacaoUsuario.INDIFERENTE ? classes.botaoClicado : classes.botaoNormal}
        >
          <IndiferenteIcon />
        </Button>
      </Tooltip>
      <Tooltip text='Satisfeito'>
        <Button
          size='small'
          onClick={onFaceClicked(GrauSatisfacaoUsuario.SATISFEITO)}
          style={value === GrauSatisfacaoUsuario.SATISFEITO ? classes.botaoClicado : classes.botaoNormal}
        >
          <SatisfeitoIcon />
        </Button>
      </Tooltip>
      <Tooltip text='Muito satisfeito'>
        <Button
          size='small'
          onClick={onFaceClicked(GrauSatisfacaoUsuario.MUITO_SATISFEITO)}
          style={value === GrauSatisfacaoUsuario.MUITO_SATISFEITO ? classes.botaoClicado : classes.botaoNormal}
        >
          <MuitoSatisfeitoIcon />
        </Button>
      </Tooltip>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  botaoNormal: {
    alignItems: 'center',
    width: '3.5rem !important',
    height: '3rem !important',
    '> span > svg > g > path': { fill: theme.pallete.gray.c40 },
    '> span > svg > g > g': { fill: theme.pallete.gray.c40 },
    '&:hover': {
      '> span> svg > g > path': { fill: theme.pallete.primary.c20 },
      '> span> svg > g > g': { fill: theme.pallete.primary.c20 },
    },
  } as React.CSSProperties,

  botaoClicado: {
    alignItems: 'center',
    width: '3.5rem !important',
    height: '3rem !important',
    '> span > svg > g > path': { fill: theme.pallete.primary.c20 },
    '> span > svg > g > g': { fill: theme.pallete.primary.c20 },
    backgroundColor: theme.pallete.primary.c90,
    border: 'solid 1px' + theme.pallete.primary.c40,
  } as React.CSSProperties,
})
