import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { CidadaoSelectFieldItem } from 'components/form/field/select/CidadaoSelectField/CidadaoSelectFieldItem'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { CidadaoSelectFieldDocument } from 'graphql/hooks.generated'
import { CidadaoSelectFieldQuery, CidadaoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

export interface CidadaoSelectFieldProps
  extends Omit<SelectFieldProps<CidadaoSelectFieldModel>, 'items' | 'itemToString'> {
  ativo?: boolean
  obito?: boolean
}

export type CidadaoSelectFieldModel = CidadaoSelectFieldQuery['cidadaos']['content'][0]

export function CidadaoSelectField(props: CidadaoSelectFieldProps) {
  const { ativo, obito, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    CidadaoSelectFieldModel,
    CidadaoSelectFieldQuery,
    CidadaoSelectFieldQueryVariables
  >({
    query: CidadaoSelectFieldDocument,
    extractItems: (data) => data && data.cidadaos.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
        ativo: ativo,
        obito: obito,
      },
    }),
  })

  const itemToString = (item: CidadaoSelectFieldModel) => item?.nomeSocial || item?.nome

  const renderItem = (item: CidadaoSelectFieldModel) => {
    return item && <CidadaoSelectFieldItem item={item} />
  }

  return (
    <SelectField<CidadaoSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...asyncProps}
      {...rest}
    />
  )
}
