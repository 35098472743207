import { DateRange, DateRangePicker, DateRangePickerProps } from 'bold-ui'
import React from 'react'

import { UseFieldProps, usePecField } from '../final-form/useField'
import { getFieldError } from '../final-form/util'
export interface LocalDateRange {
  startDate?: LocalDate
  endDate?: LocalDate
}

export type DateRangeFieldProps = UseFieldProps<LocalDateRange | DateRange> & Omit<DateRangePickerProps, 'name'>

export default function DateRangeField(props: DateRangeFieldProps) {
  const { input, meta, ...rest } = usePecField(props)

  return <DateRangePicker error={getFieldError(meta)} {...input} {...rest} />
}
