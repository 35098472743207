/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, InfoLabel, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoMarcadoresConsumoAlimentarQuery } from 'graphql/hooks.generated'
import { compact } from 'lodash'
import { memo } from 'react'
import { stringGrouper } from 'util/string/string-grouper'
import { HistoricoDwPanelProps } from 'view/atendimentos/detail/historico/model/historicoModel'
import { mountInfosAdministrativas } from 'view/atendimentos/detail/historico/utils/historicoUtils'

import HistoricoDetailTable from '../../../table/HistoricoDetailTable'
import HistoricoGapPanel from '../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../HistoricoNoDetailAvailable'

interface HistoricoMarcadoresConsumoAlimentarPanelProps extends HistoricoDwPanelProps {}

function HistoricoMarcadoresConsumoAlimentarPanel(props: HistoricoMarcadoresConsumoAlimentarPanelProps) {
  const { uuidAtend, cpfOuCnsCidadao, idadeOrigemAtendimento } = props
  const {
    data: { historicoConsumoAlimentarDetail: historico },
    loading,
  } = useHistoricoMarcadoresConsumoAlimentarQuery({
    variables: {
      input: {
        cpfOuCnsCidadao,
        uuidAtend,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />

  if (!historico) return <HistoricoNoDetailAvailable />

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={mountInfosAdministrativas(historico)}
    >
      <VFlow vSpacing={0.5}>
        <Heading level={3}>{historico?.questionario}</Heading>
        {historico.perguntasRespostas.map(
          (perguntaResposta, index) =>
            compact(perguntaResposta.respostas).length > 0 && (
              <InfoLabel title={perguntaResposta.pergunta} key={index}>
                {perguntaResposta.respostas && stringGrouper(...perguntaResposta.respostas)}
              </InfoLabel>
            )
        )}
      </VFlow>
      <HistoricoDetailTable title={historico.subtitle} sectionStyle='I'>
        <VFlow
          vSpacing={0.5}
          style={css`
            padding: 0.5rem;
          `}
        >
          {historico.perguntasRespostasOntem?.map((perguntaResposta, index) => (
            <InfoLabel title={perguntaResposta.pergunta} key={index}>
              {perguntaResposta.respostas && stringGrouper(...perguntaResposta.respostas)}
            </InfoLabel>
          ))}
        </VFlow>
      </HistoricoDetailTable>
    </HistoricoGapPanel>
  )
}

export default memo(HistoricoMarcadoresConsumoAlimentarPanel)
