import { HFlow } from 'bold-ui'
import { ExposedDropdown } from 'components/dropdown/ExposedDropdown'
import { OrdemListaAtendimentoEnum } from 'graphql/types.generated'
import React from 'react'

import { ListaAtendimentoFilterModel } from './ListaAtendimentoFilter'

export interface ListaAtendimentoSortDropdownProps {
  filter: ListaAtendimentoFilterModel
  onChange(filter: ListaAtendimentoFilterModel): void
}

export interface ListaAtendimentoDescription {
  descricao: string
}

const ListaAtendimentoSortMap: { [key in OrdemListaAtendimentoEnum]: ListaAtendimentoDescription } = {
  [OrdemListaAtendimentoEnum.CLASSIFICACAO_RISCO]: { descricao: 'Classificação de risco' },
  [OrdemListaAtendimentoEnum.ORDEM_CHEGADA_CRESCENTE]: { descricao: 'Ordem de chegada crescente' },
  [OrdemListaAtendimentoEnum.ORDEM_CHEGADA_DECRESCENTE]: { descricao: 'Ordem de chegada decrescente' },
}

export function ListaAtendimentoSortDropdown(props: ListaAtendimentoSortDropdownProps) {
  const { onChange, filter } = props

  const onChangeSelect = (sort: OrdemListaAtendimentoEnum) => {
    onChange({
      ...props.filter,
      ordem: sort,
    })
  }

  const itemToString = (sort: OrdemListaAtendimentoEnum) => sort && ListaAtendimentoSortMap[sort]?.descricao

  return (
    <HFlow hSpacing={0.25} alignItems='center' justifyContent='flex-end'>
      <ExposedDropdown<OrdemListaAtendimentoEnum>
        label='Ordenar por:'
        selectedItem={filter.ordem}
        items={Object.keys(ListaAtendimentoSortMap) as OrdemListaAtendimentoEnum[]}
        itemToString={itemToString}
        onChange={onChangeSelect}
      />
    </HFlow>
  )
}
