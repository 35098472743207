import React, { PropsWithChildren } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { UseFieldProps, usePecField } from '../final-form/useField'

export interface AccordionFieldProps extends UseFieldProps<(string | number)[]> {
  allowMultipleExpanded?: boolean
  allowZeroExpanded?: boolean
  className?: string
}

export function AccordionField(props: PropsWithChildren<AccordionFieldProps>) {
  const { allowMultipleExpanded, allowZeroExpanded, className, children, ...rest } = props
  const {
    input: { value, onChange },
  } = usePecField(rest)

  return (
    <Accordion
      preExpanded={value}
      onChange={onChange}
      allowMultipleExpanded={allowMultipleExpanded}
      allowZeroExpanded={allowZeroExpanded}
      className={className}
    >
      {children}
    </Accordion>
  )
}
