import { Text, Theme, useStyles } from 'bold-ui'
import { useSobreQuery } from 'graphql/hooks.generated'
import logo from 'images/esus.svg'
import React from 'react'

export function InitialContent({ children }) {
  const { classes } = useStyles(createStyles)

  const {
    data: { info },
  } = useSobreQuery({ fetchPolicy: 'cache-first' })

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={logo} alt='Logo do e-SUS Atenção Primária' />
      </div>
      {children}
      <div className={classes.info}>
        <p>
          Sistema fornecido pelo <br />
          <Text fontWeight='bold'>Ministério da Saúde</Text>
        </p>
        <hr className={classes.separator} />
        {info && (
          <p>
            <Text fontStyle='italic'>Versão {info?.versao}</Text>
          </p>
        )}
      </div>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%',
  } as React.CSSProperties,
  logo: {
    marginBottom: '4rem',
    textAlign: 'center',
    img: {
      height: 50,
    },
  } as React.CSSProperties,
  info: {
    marginTop: '4rem',
    textAlign: 'center',
  } as React.CSSProperties,
  separator: {
    width: '3rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  } as React.CSSProperties,
})
