import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import theme from 'config/theme'
import { useUnificacaoCidadaosListingLazyQuery } from 'graphql/hooks.generated'
import React, { useState } from 'react'
import { CidadaoPesquisarForm } from 'view/cidadao/list/CidadaoPesquisarForm'
import CidadaoPesquisarFormModel, { CidadaoListingFormModel } from 'view/cidadao/types/CidadaoPesquisarFormModel'

import { UnificacaoCadastroListing } from './UnificacaoCadastroListing'

export function UnificacaoCadastroView() {
  const alert = useAlert()
  const [cidadaosEncontrados, setCidadaosEncontrados] = useState<CidadaoListingFormModel[]>()
  const [hasMoreResults, setHasMoreResults] = useState<boolean>(false)

  const [executeQuery] = useUnificacaoCidadaosListingLazyQuery({
    onCompleted: (dataCidadaos) => {
      if (dataCidadaos?.listagemCidadaos?.content.length === 0) {
        alert('warning', 'Nenhum resultado encontrado.')
      }
      if (dataCidadaos?.listagemCidadaos?.content.length > 50) {
        alert(
          'info',
          'Foram encontrados muitos cadastros com os dados informados. Refine a busca incluindo mais informações.'
        )
      }
      setCidadaosEncontrados(dataCidadaos?.listagemCidadaos?.content)
      setHasMoreResults(dataCidadaos?.listagemCidadaos?.content.length > 50)
    },
  })

  const handleSubmitPesquisa = async (form: CidadaoPesquisarFormModel) => {
    if (!form.nomeCpfCns && !form.dataNascimento && !form.nomeMae && !form.municipio) {
      alert('info', 'Insira algum filtro para realizar a busca.')
    } else {
      executeQuery({
        variables: {
          input: {
            pageParams: {
              size: 51,
              fetchPageInfo: false,
            },
            query: form.nomeCpfCns,
            dataNascimento: form.dataNascimento,
            nomeMae: form.nomeMae,
            municipioNascimento: form.municipio?.id,
          },
        },
      })
    }
  }

  const handleClearCidadaosEncontrados = () => {
    setCidadaosEncontrados(null)
  }

  return (
    <>
      <PageHeaderSection title='Unificação de cadastros' />
      <VFlow style={{ borderBottom: `1px solid ${theme.pallete.divider}` }}>
        <PageContainer>
          <CidadaoPesquisarForm handleSubmitPesquisa={handleSubmitPesquisa} />
        </PageContainer>
      </VFlow>
      <PageContent type='transparent' style={{ textAlign: 'center' }}>
        <UnificacaoCadastroListing
          cadastrosEncontrados={cidadaosEncontrados}
          hasMoreResults={hasMoreResults}
          clearCidadaosEncontrados={handleClearCidadaosEncontrados}
        />
      </PageContent>
    </>
  )
}
