/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text } from 'bold-ui'
import { TextField, TextFieldProps } from 'components/form'
import { usePecField } from 'components/form/final-form/useField'
import { getFieldError } from 'components/form/final-form/util'

export interface PeriogramaSimplificadoTextFieldProps extends Omit<TextFieldProps, 'parse' | 'maxLength' | 'error'> {}

export default function PeriogramaSimplificadoTextField(props: PeriogramaSimplificadoTextFieldProps) {
  const { label, ...rest } = props
  const { meta } = usePecField({ name: props.name })

  return (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Text fontWeight='bold'>{label}</Text>
      <TextField
        style={css`
          width: 3rem;
        `}
        maxLength={2}
        parse={parse}
        clearable={false}
        {...rest}
        error={null}
        invalid={!!getFieldError(meta)}
      />
    </HFlow>
  )
}

const validChars = [
  { value: '0', acceptAsterisk: true },
  { value: '1', acceptAsterisk: true },
  { value: '2', acceptAsterisk: true },
  { value: '3', acceptAsterisk: true },
  { value: '4', acceptAsterisk: true },
  { value: 'x', acceptAsterisk: false },
]

const parse = (value: string) => {
  const pieces = value.toLowerCase().split('')

  if (pieces?.length > 0) {
    if (!validChars.map(({ value }) => value).includes(pieces[0])) {
      return ''
    }

    if (pieces.length > 1) {
      const firstChar = validChars.find((pattern) => pattern.value === pieces[0])

      if (!firstChar.acceptAsterisk || (firstChar.acceptAsterisk && pieces[1] !== '*')) {
        pieces.pop()
      }
    }
  }

  return pieces.join('')
}
