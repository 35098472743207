/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, MonthViewProps, ReferenceMonth, Theme, useTheme, VFlow } from 'bold-ui'
import { createStylesFn, ModifierPredicateMap, ModifierStyleMap } from 'bold-ui/lib/components/Calendar/Calendar'
import { composeHandlers } from 'bold-ui/lib/util/react'
import { Weekday } from 'components/agenda'
import { getDay, isSameDay } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import { useEffect, useMemo, useState } from 'react'

import { CalendarAgendaLotacaoMonthPaginator } from './CalendarAgendaLotacaoMonthPaginator'
import { CalendarAgendaLotacaoMonthView } from './CalendarAgendaLotacaoMonthView'

export interface CalendarAgendaLotacaoProps extends Omit<MonthViewProps, 'createDateStyles'> {
  onChange?(date: Date): void
  availableWeekdays: Set<Weekday>
  shouldEnableControls?: boolean
}

export function CalendarAgendaLotacao(props: CalendarAgendaLotacaoProps) {
  const {
    visibleDate: currentDate,
    onChange,
    onDayClick,
    availableWeekdays,
    shouldEnableControls = false,
    ...rest
  } = props
  const { getServerTimeNow } = useServerTime()
  const theme = useTheme()
  const styles = createStyles(theme)
  const shouldDisableControls = !shouldEnableControls && !Boolean(availableWeekdays.size)

  const [visibleMonth, setVisibleMonth] = useState<ReferenceMonth>({
    month: currentDate.getMonth(),
    year: currentDate.getFullYear(),
  })

  useEffect(() => {
    setVisibleMonth({
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
    })
  }, [currentDate])

  const dayModifiers = useMemo(
    () => getDayModifiers(getServerTimeNow, availableWeekdays, visibleMonth, currentDate, shouldEnableControls),
    [getServerTimeNow, availableWeekdays, visibleMonth, currentDate, shouldEnableControls]
  )

  const visibleDate = useMemo(() => new Date(visibleMonth.year, visibleMonth.month), [visibleMonth])

  const createDateStyles = useMemo(() => createStylesFn(dayModifiers, modifierStyles, theme), [dayModifiers, theme])

  return (
    <VFlow style={styles.container} vSpacing={0.75}>
      <HFlow justifyContent='space-between'>
        <Button
          skin='outline'
          kind='primary'
          size='small'
          onClick={() => onChange(getServerTimeNow())}
          disabled={shouldDisableControls}
          data-testid='hoje-button'
        >
          Hoje
        </Button>
        <div css={modifierStyles.monthPaginator}>
          <CalendarAgendaLotacaoMonthPaginator
            onChange={setVisibleMonth}
            disabled={shouldDisableControls}
            referenceMonth={visibleMonth}
          />
        </div>
      </HFlow>
      <div
        css={css`
          margin-left: -0.5rem;
        `}
      >
        <CalendarAgendaLotacaoMonthView
          visibleDate={visibleDate}
          onChange={composeHandlers(onChange, onDayClick)}
          createDateStyles={createDateStyles}
          disabled={shouldDisableControls}
          {...rest}
        />
      </div>
    </VFlow>
  )
}

const isSameReferenceMonth = (dayOfMonth: Date, referenceMonth: ReferenceMonth): boolean => {
  return dayOfMonth.getMonth() === referenceMonth.month && dayOfMonth.getFullYear() === referenceMonth.year
}

const isDaySelected = (selectedDate: Date, dayOfMonth: Date): boolean => {
  return selectedDate.getTime() === dayOfMonth.getTime()
}

const isToday = (dayOfMonth: Date, referenceMonth: ReferenceMonth, getServerTimeNow: () => Date): boolean => {
  return isSameReferenceMonth(dayOfMonth, referenceMonth) && isSameDay(getServerTimeNow(), dayOfMonth)
}

const getDayModifiers = (
  getServerTimeNow: () => Date,
  availableWeekdays: Set<Weekday>,
  visibleMonth: ReferenceMonth,
  selectedDate: Date,
  shouldEnableControls: boolean
): ModifierPredicateMap => ({
  today: (dayOfMonth: Date) => isToday(dayOfMonth, visibleMonth, getServerTimeNow),
  disabled: (dayOfMonth: Date) => !shouldEnableControls && !availableWeekdays.has(getDay(dayOfMonth)),
  selected: (dayOfMonth: Date) => isDaySelected(selectedDate, dayOfMonth),
  adjacentMonth: (dayOfMonth: Date, { visibleDate }) => visibleDate.getMonth() !== dayOfMonth.getMonth(),
})

const modifierStyles: ModifierStyleMap = {
  today: (theme: Theme) => css`
    font-weight: bold;
    color: ${theme.pallete.primary.main};
  `,
  disabled: (theme: Theme) => css`
    color: ${theme.pallete.gray.c50};
    &:hover {
      background: initial;
    }
  `,
  selected: (theme: Theme) => css`
    background: ${theme.pallete.primary.main};
    color: ${theme.pallete.surface.main};
    font-weight: bold;
    &:hover {
      background: ${theme.pallete.primary.c30};
      color: ${theme.pallete.surface.main};
    }
  `,
  adjacentMonth: (theme: Theme) => css`
    color: ${theme.pallete.text.disabled};
  `,
}

const createStyles = (theme: Theme) => ({
  container: css`
    width: 100%;
    max-width: 16rem;
    padding: 0.5rem 1rem;
    background-color: ${theme.pallete.surface.main};
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 2px;
  `,
})
